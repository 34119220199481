import React, { useMemo } from "react";
import { CustomProperties, WrapperField } from "../";

const getFieldGroups = (grouping, fields) => grouping && fields.groupBy((x) => ({name: x.categoria}), (category, group) => ({name: category.name, group}));
  

export const CustomGridProperties = (props) => {
  //console.log(props)
  const { name, onChange, formik, fields, initialValues, grouping = true } = props;
  const fieldGroups = useMemo(()=>getFieldGroups(grouping, fields), [grouping, fields]);
  const handleChange = (values) => {
    formik && formik.setFieldValue(name, values)
    onChange && onChange(values);
  }
  return (
    <CustomProperties  
      spacing={0} 
      Wrapper={WrapperField} 
      {...props} 
      onChange={handleChange}
      fieldGroups={fieldGroups}
      initialValues={formik.values[name] || initialValues}
      formik={undefined}
    />
  )
}