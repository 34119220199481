import React, { useCallback, useState } from "react";
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { Flag } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import { Link, Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid } from "@material-ui/core";
import { DetailWrapperGiz, WrapperComponent } from "../wrappers";
import { valueResolver } from "../../../redux/utils";


const resultadoColor = ["", "green", "yellow", "red"];

export const TimelineOrden = (props) => {

  const [itemDialog, setItemDialog] = useState();
  const { data } = props;
  
  const handleDatosClose = useCallback(()=>{
    setItemDialog(undefined);
  }, [])

  return (
    <>
      <DatosDialog item={itemDialog} onClose={handleDatosClose}/>

      <Timeline position="right">
        {
          data && data.evoluciones.map(item => 
            <TimelineItem style={{}}>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot>
                  <Flag style={{fill: resultadoColor[item.resultado]}} />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="div">
                  {item.estado}
                </Typography>
                <Typography variant="body1" component="div">
                  {item.tarea && item.tarea}{item.paso && <Link to="" onClick={()=>setItemDialog(item)}>{` > ${item.paso}`}</Link>}
                </Typography>
                <Typography variant="body2" component="div">
                  {item.fechaHora}
                </Typography>
                <Typography>{item.observacion}</Typography>
                
              </TimelineContent>
            </TimelineItem>
          )
        }

      </Timeline>
    </>
  )
}


const DatosDialog = ({item, onClose}) => {
console.log(item)
  return (
    <Dialog onClose={onClose} open={Boolean(item)} keepMounted={false} maxWidth="md">
      <DialogTitle>Datos del paso</DialogTitle>
      <DialogContent>
        <Grid container>
          {item && item.datos && item.datos.map( dato =>
            <>
              <WrapperComponent caption={dato.nombre} Field={valueResolver[dato.tipoRespuesta](dato.valor)}>
              </WrapperComponent>          
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  )
}