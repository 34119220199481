import React, { useEffect, useState } from 'react';
import { DataGridIndex } from '../../helpers/DataGridIndex';
import { CardContent, CardHeader, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { useHistory } from 'react-router';
import Swal from 'sweetalert2';
import { MapGiz } from '../../helpers';

const List = (props) => {

  const history = useHistory();
  const { removeItem, snack, config, index, list } = props;
  const [map, setMap] = useState({show: false, lat: undefined, lng: undefined});

  useEffect(()=>{
    index()
      .then(()=>{
        console.log("ok")
      })
      .catch(()=>{
        console.log("error")
      })
  },[])

  const handleCopyClick = (params) => {
    history.push(`Add/${params.id}`);
  }
  
  const handleAddClick = () => {
    history.push("Add");
  }

  const handleEditClick = (params, event) => {
    history.push(`Edit/${params.id}`);
  }

  const handleDetailsClick = (params, event) => {
    history.push(`Details/${params.id}`);
  }

  const handleTimelineClick = (params, event) => {
    history.push(`Timeline/${params.id}`);
  }
  
  const handleMapClick = (params, event) => {
    setMap({show: true, lat: params.row.lat, lng: params.row.lng});
  }

  const handleMapClose = () => {
    setMap({show: false, lat: undefined, lng: undefined});
  }

  const handleDeleteClick = (params, event) => {
    event.preventDefault();
    const itemDescription = params
      .api
      .getAllColumns()
      .filter(x => x.field !== "acciones")
      .map(x => {
        const text = x.renderCell ? x.renderCell(params) : params.row[x.field];
        return text ? `${x.headerName}: ${text}` : '';
      }) 
      .join("<br/>");

    Swal
      .fire({
        icon: 'question',
        title: 'Eliminar elemento',
        html: `Está seguro de eliminar este elemento?<br/> <span>${itemDescription}</span>`,
        showCancelButton: true,
        confirmButtonText: 'Eliminar',
        cancelButtonText: 'Cerrar',
      })
      .then((result) => {
        if (result.isConfirmed) {
          removeItem(params.id).then(
            ()=>{
              index();
              snack.openSuccess('El elemento se eliminó correctamente.');
            },
            error => snack.openAxiosError(error)
          );
        }
      });

  }
  
  /*
  const handleChange = (event) => {
      handleControlChange[event.target.name] && handleControlChange[event.target.name](event.target.value); 
      formik.handleChange(event);
  }
  */

  return (
    <>
      
      <Dialog fullWidth onClose={handleMapClose} open={map.show} keepMounted={false} maxWidth="xl">
        <DialogTitle>Ubicación de la orden</DialogTitle>
        <DialogContent>
          <MapGiz readonly={true} point={map} />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleMapClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <CardHeader
        action={
          <>
          {
            config.policy.add &&
            <Button onClick={handleAddClick}>
              Agregar
            </Button>
          }
          </>
        }
        title={config.caption}
      />
      <CardContent>
        <DataGridIndex 
          fileName={config.caption} 
          rows={list} 
          columns={config.columns({
            onDetails: config.policy.details && handleDetailsClick, 
            onEdit: config.policy.edit && handleEditClick, 
            onDelete: config.policy.delete && handleDeleteClick, 
            onCopy: config.policy.copy && handleCopyClick,
            onTimeline: config.policy.timeline && handleTimelineClick,
            onMap: config.policy.map && handleMapClick,
          })
          }
        />
      </CardContent>
    </>
  );
};

export default List;