import { Grid, ListItem, ListItemText } from "@material-ui/core";
import React from "react"
import { getFieldForm } from "../../../redux/utils";

const styleCaption = {        
  border: "0px solid white",
  borderBottom: "1px solid white",
  borderRadius: "0px",
  backgroundColor: "#eaeaea",
  padding: 0,
  display: "flex",
  alignItems: "center",
};
const styleInput = {        
  border: "0px solid white",
  borderBottom: "1px solid white",
  borderRadius: "0px",
  backgroundColor: "#f7f7f7",
  padding: 0,
  display: "flex",
  alignItems: "center",
};

export const WrapperField = ({fieldProps, Field, fake = false}) => {
  //console.log(!fieldProps.field || !fieldProps.field.fieldFn || eval(fieldProps.field.fieldFn)(fieldProps) );
  return (
    <>
      {Field && (!fieldProps.field || !fieldProps.field.type || getFieldForm(fieldProps.field)) &&
        (!fieldProps.field || !fieldProps.field.fieldFn || getFieldForm(eval(fieldProps.field.fieldFn)(fieldProps).field)) &&
        <WrapperComponent 
          caption={fieldProps.field.caption}
          Field={<Field fake={fake} {...{...fieldProps, field: {...fieldProps.field, columns: 12, variant: "standard", size: "small"}}}/>}
        />
      }
    </>
  );

}

export const WrapperComponent = ({caption, Field}) => {
  return (
    <>
      {Field && caption &&
        <>
          <Grid item xs={4} style={styleCaption}>
            <ListItem disablePadding>
              <ListItemText primary={caption} />
            </ListItem>
          </Grid>
          <Grid item xs={8} style={styleInput}>
            {Field}
          </Grid>    
        </>
      }
      {Field && !caption &&
        <>
          <Grid item xs={12} style={styleInput}>
            {Field}
          </Grid>    
        </>
      }

    </>
  )
}
