import React, { useEffect, useState } from "react"
import Select from 'react-select'
import { MultiSelectGizStyles } from "./";
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { ListSubheader } from "@material-ui/core";

const placeholder = "Seleccione un cliente...";

export const SelectClienteGiz = ({formik, name, data}) => {
  
  const getClienteFromFormik = () => {
    return formik.values._cliente && formik.values._cliente.idCliente && data.clientes.find(x => x.id === formik.values._cliente.idCliente);
  }
  
  const getDireccionFromFormik = () => {
    const cliente = getClienteFromFormik();
    return cliente && formik.values._cliente.idDireccion && cliente.direcciones && cliente.direcciones.find(x => x.id === formik.values._cliente.idDireccion);
  }
  
  const [state, setState] = useState({
    loaded: false,
    cliente: getClienteFromFormik(), 
    direccion: getDireccionFromFormik(), 
  });

  useEffect(()=>{
    setState(state => ({...state, loaded: true}));
  }, [])


  const handleClienteChange = (value) => {

    if(state.loaded){
      const cliente = data.clientes.find(x => x.id === value.value);
      const direccion = cliente.direcciones && cliente.direcciones.length === 1 && cliente.direcciones[0];
  
      setState({
        ...state, 
        cliente, 
        direccion
      })
      updateFormik(cliente, direccion);
    }
  }

  const handleDireccionChange = (direccion) => {
    if(state.loaded){
      setState({...state, direccion})
      updateFormik(state.cliente, direccion);
    }
  }

  const updateFormik = (cliente, direccion) => {
    formik.setFieldValue("_cliente", {idCliente: cliente && cliente.id, idDireccion: direccion && direccion.id})
  }

  const options = data.clientes.map(x=>({value: x.id, label: [x.apellido, x.nombre, x.nroDocumento].filter(x => x).join(", ") }));

  return (
    <>
      <div  style={{ margin: 8, backgroundColor: "#f4f4f4", padding: 8 }}>
        <Select
          isDisabled={false}
          isMulti={false}
          name={name}
          options={options}
          value={state.cliente && options && options.find(x => x.value === state.cliente.id) }
          onChange={value => handleClienteChange(value)}
          placeholder={placeholder}
          menuPortalTarget={document.body}
          menuPosition="fixed"
          styles={MultiSelectGizStyles.standard()}
        />
      </div>
      {state.cliente &&
        <div  style={{ margin: 8 }}>
          <CheckboxList loaded={state.loaded} direccion={state.direccion} direcciones={state.cliente.direcciones.sortByProp("orden")} onChange={handleDireccionChange}/>
        </div>
      }
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const CheckboxList = (props) => {

  const classes = useStyles();
  const [checked, setChecked] = useState(props.direccion && props.direccion.id);

  const handleToggle = (item) => () => {
    setChecked(checked !== item.id ? item.id : null);
    props.onChange(checked !== item.id ? item : null);
  };

  useEffect(()=>{
    setChecked(props.direccion && props.direccion.id);
  }, [props.direccion])

  return (
    <List className={classes.root}>
      <ListSubheader>
        <ListItem>
        <ListItemText primary={(props.direcciones && props.direcciones.length > 0) ? "Seleccione una dirección..." : "El cliente no tiene direcciones"} />
        </ListItem>
      </ListSubheader>
      {props.direcciones && props.direcciones.map((item, index) => {
        const labelId = `checkbox-list-label-${index}`;
        return (
          <ListItem key={index} role={undefined} dense button onClick={handleToggle(item)}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={item.id === checked}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemIcon>
            <ListItemText id={labelId} primary={[item.direccion, item.localidad, item.provincia].filter(x => x).join(", ")} />
          </ListItem>
        );
      })}
    </List>
  );
}