export { prefabAdjuntarArchivo } from "./prefabAdjuntarArchivo";
export { prefabCapturarNotaVoz } from "./prefabCapturarNotaVoz";
export { prefabCapturarVideo } from "./prefabCapturarVideo";
export { prefabTomarFoto } from "./prefabTomarFoto";
export { prefabEscanearBarra } from "./prefabEscanearBarra";
export { prefabEscanearQR } from "./prefabEscanearQR";
export { prefabLeerNFC } from "./prefabLeerNFC";
export { prefabContrasenia } from "./prefabContrasenia";
export { prefabDeslizableCompacto } from "./prefabDeslizableCompacto";
export { prefabFecha } from "./prefabFecha";
export { prefabHora } from "./prefabHora";
export { prefabTextoCompacto } from "./prefabTextoCompacto";
export { prefabNumeroCompacto } from "./prefabNumeroCompacto";
export { prefabNumeroMinMaxVuCompacto } from "./prefabNumeroMinMaxVuCompacto";
export { prefabNumeroMinMaxCompacto } from "./prefabNumeroMinMaxCompacto";
export { prefabSiNoCompacto } from "./prefabSiNoCompacto";
export { prefabSeleccionHorizontalSimpleOpcionesCompacta } from "./prefabSeleccionHorizontalSimpleOpcionesCompacta";
export { prefabSeleccionHorizontalMultipleOpcionesCompacta } from "./prefabSeleccionHorizontalMultipleOpcionesCompacta";
export { prefabSeleccionSINONACompacta } from "./prefabSeleccionSINONACompacta";
export { prefabTituloSeleccionHorizontalCompacta } from "./prefabTituloSeleccionHorizontalCompacta";
export { prefabPintarCompacto } from "./prefabPintarCompacto";
export { prefabSeleccionColor } from "./prefabSeleccionColor";
export { prefabTextoCompleto } from "./prefabTextoCompleto";
export { prefabDeslizableCompleto } from "./prefabDeslizableCompleto";
export { prefabNumeroMinMaxCompleto } from "./prefabNumeroMinMaxCompleto";
export { prefabNumeroCompleto } from "./prefabNumeroCompleto";
export { prefabNumeroMinMaxVuCompleto } from "./prefabNumeroMinMaxVuCompleto";
export { prefabPintarCompleto } from "./prefabPintarCompleto";
export { prefabSeleccionHorizontalMultipleOpcionesCompleta } from "./prefabSeleccionHorizontalMultipleOpcionesCompleta";
export { prefabSeleccionHorizontalSimpleOpcionesCompleta } from "./prefabSeleccionHorizontalSimpleOpcionesCompleta";
export { prefabSeleccionSINONACompleta } from "./prefabSeleccionSINONACompleta";
export { prefabEstadoRojoAVerde } from "./prefabEstadoRojoAVerde";
export { prefabEstadoVerdeARojo } from "./prefabEstadoVerdeARojo";
export { prefabSiNoCompleto } from "./prefabSiNoCompleto";
export { prefabSeleccionDolor } from "./prefabSeleccionDolor";
export { prefabFirmar } from "./prefabFirmar";
export { prefabSeleccionVerticalMultipleOpciones } from "./prefabSeleccionVerticalMultipleOpciones";
export { prefabSeleccionVerticalSimpleOpciones } from "./prefabSeleccionVerticalSimpleOpciones";
export { prefabSeleccionVerticalMultipleImagenes } from "./prefabSeleccionVerticalMultipleImagenes";
export { prefabSeleccionVerticalSimpleImagenes } from "./prefabSeleccionVerticalSimpleImagenes";
export { prefabSeleccionHorizontalMultipleImagenes } from "./prefabSeleccionHorizontalMultipleImagenes";
export { prefabSeleccionHorizontalSimpleImagenes } from "./prefabSeleccionHorizontalSimpleImagenes";
export { prefabImagenUI } from "./prefabImagenUI";          
export { prefabImagenImagenUI } from "./prefabImagenImagenUI";
export { prefabTextoUI } from "./prefabTextoUI";  
export { prefabTextoTextoUI } from "./prefabTextoTextoUI";  
export { prefabTextoImagenUI } from "./prefabTextoImagenUI";  
export { prefabImagenTextoUI } from "./prefabImagenTextoUI";  
export { prefabHtml5 } from "./prefabHtml5";  
export { prefabPdf } from "./prefabPdf";  
export { prefabLineaDivisoria } from "./prefabLineaDivisoria";  

export * from "./base";