
String.prototype.toEventValue = function (name) {
  return {target: {name: name, value: this}};
}

Number.prototype.toEventValue = function (name) {
  return {target: {name: name, value: this}};
}

String.prototype.uuidv4 = function () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}