import React, {useEffect, useState} from "react"
import { DatePicker as Dtpicker, DateTimePicker as DTPicker } from '@material-ui/lab';
import { Grid, TextField } from "@material-ui/core";
import { TimePicker as TPicker } from '@material-ui/lab';
export const DateTimePicker = ({name, details = false, caption = "Fecha", formik, variant = "outlined", placeholder, onChange, minDate, invalidDateMessage, minDateMessage, maxDateMessage}) => {

  const [state, setState] = useState({value: formik.values[name]})

  useEffect(()=>{
    state.value !== formik.values[name] && formik.setFieldValue(name, state.value);
  }, [state.value])

  return (
    <div style={{ padding: 8 }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Dtpicker
            disabled={details}
            label={variant == "outlined" && caption}
            value={state.value}
            onChange={(value)=>{
              setState({...state, value}) 
            }}
            renderInput={(params) => <TextField {...params} helperText="" fullWidth variant={variant} />}
          />
        </Grid>
        <Grid item xs={6}>
          <TPicker
            disabled={details}
            label={variant == "outlined" && caption}
            value={state.value}
            onChange={(value)=>{ 
              setState({...state, value}) 
            }}
            renderInput={(params) => <TextField {...params} helperText="" fullWidth variant={variant} />}
          />
        </Grid>
      </Grid>
    </div>
  )
}

