import React from "react"
import { TextField } from "@material-ui/core"
import Autocomplete from '@material-ui/core/Autocomplete';

export const AutocompleteGiz = ({name, label, formik, placeholder, onChange, onInputChange, type, autoFocus, options, getOptionLabel, value, autocompleteRef}) => {


  return (
    
    <Autocomplete
      fullWidth
      options={options}
      getOptionLabel={getOptionLabel ? getOptionLabel : (option) => option.nombre}
      onInputChange={onInputChange}
      onChange={onChange}
      
      renderInput={(params) => {
        //onInputChange && onInputChange(params)
        return (
          <TextField 
            ref={autocompleteRef}
            autoFocus={autoFocus}
            fullWidth
            type={type}
            variant="outlined"
            id={name}
            name={name}
            label={label}
            style={{ margin: 8 }}
            placeholder={placeholder}
            value={formik ? formik.values[name] : value}
            error={formik && (formik.touched[name] && Boolean(formik.errors[name]))}
            helperText={formik && (formik.touched[name] && formik.errors[name])}
            {...params}
          />
        )
      }}
    />
  )
}

