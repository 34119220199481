import React, { useEffect, useState } from "react";

export const PrefabLayoutUI = ({list, style = {}, defaultValue = [], itemStyle = {} }) => {

  const [value, setValue] = useState(() => defaultValue)

  useEffect(()=>{
    list && setValue(list.slice(0, 6));
  }, [list])

  return (
    <div style={{fontSize: 10, padding: 1, whiteSpace: "nowrap", display: "flex", alignItems: "stretch", flexDirection: "row", ...style} }>
      {value.map((x, index) => 
        <div key={index} style={{flex: 1, display: "flex", flexDirection: "column", overflowX: "hidden", ...itemStyle}}>{x}</div>
      )}
    </div>
  )
}

