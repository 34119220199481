import React, { useMemo } from 'react';
import { ListItem, Grid, Box, Button } from '@material-ui/core';

import * as images from "../../assets/images/prefabs";
import * as prefabs from "../prefabs";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { SortableListDnd, SortableItemDnd } from "../" ;

const itemStyle = {marginTop: "8px", backgroundColor: "#2aaede05" }

export const SortableListCampos = ({caption, details, onReorderList, rows, columns, addItem, item, data}) => {
  return (
    <Box style={{ width: "100%"}}>
      <SortableListDnd name="Campos" onSortEnd={onReorderList} rows={rows}>
        {({name, rows, items}) => 
          <div> 
            <Titulo caption={caption}/>
            <Grid container>
              {rows && rows.map((item, index) => (
                <SortableItemDnd key={items[index]} id={items[index]} {...{item, caption, details, columns, data}}>
                  {(props) => 
                    <Item {...props}/>
                  }
                </SortableItemDnd>
              ))}        
            </Grid>
            <>
              {addItem && !details && 
                <ListItem style={{...itemStyle, border: "1px #2aaede dashed"}} >
                  <Button fullWidth variant="text" onClick={addItem}>Agregar Campo</Button>
                </ListItem>
              }
            </>
          </div>
        }
      </SortableListDnd>
    </Box>
  );
}

const Titulo = ({caption}) =>{
  return (
    <Grid item xs={12} style={{padding: 0, display: "flex", flexWrap: "nowrap", borderBottom: "1px #c3c3c3 solid", backgroundColor: "rgba(255,255,255,0.9)"}}>
        <div style={{ flex: 1, display: "flex", alignItems: "center" }}>         
          <div style={{flex: 1, overflowWrap: "anywhere"}}></div>
          <div
            style={{
              padding: 2,
              width: 338,
              display: "flex",
              backgroundColor: "#0D9FCA",
              textAlign: "center",
              flexDirection: "column",
              borderRadius: "10px 10px 0px 0px",
              color: "white",
              fontWeight: 500,
            }}
          >
            {caption}
          </div>      
        </div>      
    </Grid>    
  )
}

const getTipoCampo = (tiposCampo, item) => tiposCampo.find(x => x.value === item.idTipoCampo)
const getPrefab = (tipoCampo) => prefabs[tipoCampo ? tipoCampo.image : ""]
const getAcciones = (columns, item) => columns.filter(x => x.field === "acciones")[0].renderCell({row: item})

const Item = ({listeners, rootProps, isDragging, item, details, columns, data}) =>{

  const style = useMemo(()=>({
    padding: 0, 
    display: "flex", 
    flexWrap: "nowrap", 
    borderBottom: isDragging ? "0px" : "1px rgba(0,0,0,0.1) solid", 
    backgroundColor: "rgba(255,255,255,1)",    
  }), [isDragging]);;

  const tiposCampo = data.tiposCampo;
  const tipoCampo = useMemo(()=>getTipoCampo(tiposCampo, item), [item]);
  const Prefab = useMemo(()=>getPrefab(tipoCampo), [tipoCampo]);
  const acciones = useMemo(()=>getAcciones(columns, item), [item]);
  const itemProps = useMemo(()=>({...rootProps, style: {...rootProps.style, ...style}}), [rootProps, style]);

  return (
    <Grid {...itemProps} item xs={12} >
      {!details && 
        <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
          <DragIndicatorIcon  {...listeners}/>
          {acciones} 
          <div style={{flex: 1, overflowWrap: "anywhere"}}>{item.identificacion && item.identificacion.nombre}</div>
        </div>      
      }
      {
        Prefab
        ?<Prefab
          style={{padding: 0, maxWidth: 338, objectFit: "scale-down", display: "flex", alignItems: "center", backgroundColor: "#393a39"}}
          item={item}
        />
        :<img
          src={images[`${tipoCampo ? tipoCampo.image : ""}`]}
          style={{padding: 0, maxWidth: 338, objectFit: "scale-down" }}
          draggable={false}
        />  
      }
    </Grid>
  )
}