import React from "react";

export const PrefabItemSelHorizontal = ({checked, value, onClick}) => {

  return (
    <div onClick={onClick} style={{ display: "flex", flex: 1, flexDirection: "column", color: "white", fontWeight: 600, fontSize: 11, alignItems: "center"}}>
      <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
        <div style={{border: checked ? "3px solid #009fc6" : "3px solid transparent"}}>{value}</div>
      </div>
    </div>
  )
}