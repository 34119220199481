import React from "react";
import { PrefabBase, TitleComponent, InfoHTML, HistoryHTML, EmptyHTML, MicIcon } from "./";

export const PrefabBasicFull =  React.memo(({ title, onClick=()=>{}, content, content2, hasHistory = true, hasInfo, hasMic = false }) => {

  const rows = [
    {
      items: [
        {content: <InfoHTML show={hasInfo}/>, px: 16},
        {content: title, component: TitleComponent, px: 280},
        {content: hasMic ? <MicIcon style={{fill: "#0c97c0", width: 14, height: 14}}/> : <EmptyHTML/> , px: 14 },
        {content: <HistoryHTML show={hasHistory}/>, px: 28},
      ]    
    },
    {
      items: [
        {content: <EmptyHTML/>, px: 4},
        {content, px: 330},
        {content: <EmptyHTML/>, px: 4},
      ]    
    },
    content2 && {
      items: [
        {content: <EmptyHTML/>, px: 4},
        {content: content2, px: 330},
        {content: <EmptyHTML/>, px: 4},
      ]    
    },
  ];

  return (
    <PrefabBase rows={rows} onClick={onClick} />
  );
})
