import React from "react"
import { Slider, Typography } from "@material-ui/core"

export const SliderGiz = ({name, label, value, min = 0, max, step = 1, marks, error, helperText, formik, placeholder, onChange, type, autoFocus, disabled, style = {}}) => {
  const _value = value || (formik && formik.values[name]);
  return (
    <div style={{ margin: 8, ...style }}>
      <Typography id="discrete-slider" gutterBottom>
        {label} {_value!==min && <>: {_value}</>} 
      </Typography>
      <Slider
        name={name}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="auto"
        step={step}
        marks={marks}
        min={min}
        max={max}
        
        placeholder={placeholder}
        value={_value}
        onChange={onChange || (formik && formik.handleChange)}
        error={error || (formik && formik.touched[name] && Boolean(formik.errors[name]))}
        helperText={helperText || (formik && formik.touched[name] && formik.errors[name])}
        disabled={disabled} 
      />
    </div> 
  )
}

