import React from "react";
import { PrefabBase, TitleComponent, InfoHTML, HistoryHTML } from "./";

export const PrefabBasicSelect =  React.memo(({ title, onClick=()=>{}, content, hasHistory = true, hasInfo }) => {

  const rows = [
    {
      items: [
        {content: <InfoHTML show={hasInfo}/>, px: 16},
        {content: title, component: TitleComponent, px: 108},
        {content, px: 186},
        {content: <HistoryHTML show={hasHistory}/>, px: 28},
      ]    
    }
  ];

  return (
    <PrefabBase rows={rows} onClick={onClick} />
  );
})
