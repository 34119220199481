import React, { useMemo, useRef, useEffect, useState } from "react";
import { Marker, Popup, useMap } from 'react-leaflet';
import icon from "../../assets/svg/icons/Map/Marker1.svg";
import L from 'leaflet';

const markerIcon = new L.Icon({
    iconUrl: icon,
    iconRetinaUrl: icon,
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(60, 75),
    className: 'leaflet-div-icon'
});

export const MapMarker = (props) => {

  const { lat, lng, text, onChange, readonly } = props;
  const [ state, setState ] = useState({ lat, lng });
  const map = useMap();
  const markerRef = useRef(null);

  useEffect(() => {
    !readonly && map.addEventListener("click", ({ latlng }) => {
      handleChangeLocation(latlng);
    })
  }, []);

  const eventMarkerHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current
        if (marker != null) {
          handleChangeLocation(marker.getLatLng());
        }
      },
    }),
    [],
  );

  const handleChangeLocation = (latLng) => {
    onChange(latLng);
    setState({ lat: latLng.lat, lng: latLng.lng })
  }

  return (
    <>
      <Marker 
        draggable={!readonly} 
        position={[state.lat, state.lng]} 
        icon={markerIcon} 
        eventHandlers={!readonly ? eventMarkerHandlers : undefined}
        ref={markerRef}> 
        {text &&
          <Popup>
            {text}
          </Popup>
        }
      </Marker> 
    </>  
  )

}




