import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useHistory, useParams } from 'react-router';
import { Button, CardContent, Grid, makeStyles } from '@material-ui/core';
import { createValidationObject } from '../../../redux/utils';
import { Field } from '../../helpers';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import { FormProvider } from '../../contexts/FormCtx';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: '#fff',
    opacity: 1,
    backgroundColor: "#000000"
  },
}));


export const Form = (props) => {
  const { loaded, editForm, addForm, loadedProcessed, data, getTable } = props;
  let { id } = useParams();
  const [internalLoaded, setInternalLoaded] = useState(false);
  const validationSchema = createValidationObject({fields: props.config.fields});

  useEffect(() => {
    const fn = id ? ()=>editForm(id) : addForm;
    fn()
      .then(response => {
      })
      .catch(error=> {
      });
  }, [])

  useEffect(() => {
    if(loaded){
      loadedProcessed()
      setInternalLoaded(true); 
    }
  }, [loaded])

  const xData = useMemo(()=>({...data, getTable}), [data, getTable])

  return (
    <>
      {internalLoaded && <RealForm {...{...props, id, validationSchema, data: xData }}/>}
      {!internalLoaded && <FakeForm {...props}/>}
    </>
  )
}


export const FakeForm = (props) => {
  const { config } = props;
  return (
    <>
      <CardContent>
        <Grid container spacing={2}>
          {
            config.fields.map((x, index)=>
              <Field key={index} fake={true} field={x}/>
            )
          }
        </Grid>
      </CardContent>
    </>
  );
}

export const RealForm = (props) => {

  let history = useHistory();
  
  const [state, setState] = useState({});
  const { action, snack, id, config, item, data, editItem, addItem, validationSchema }  = props;

  useEffect(()=>{
    setState({loaded: true})
  }, [])

  const handleSaveClick = (event) => {
    event.preventDefault();
    formik.submitForm();
  }


  console.log("Render Form");

  const formik = useFormik({
    initialValues: item,
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: values => {
      //console.log(values);
      formik.setSubmitting(true);
      const fn = (action === "edit") ? () => editItem(id, values) : () => addItem(values);
      fn().then(response => {
        snack.openSuccess(`El elemento se ${id ? 'editó' : 'agregó'} correctamente.`,);
        history.replace( toAbsoluteUrl(config.url) );
      }).catch(error => {
        snack.openError('Error de servidor.');
        formik.setSubmitting(false);
      })
    },
  });

  useEffect(()=>{
    console.log(formik.errors);
  }, [formik.values])

  return (
    <>
    {state.loaded && 
      <FormProvider {...{...props, formik}}>
        <CardContent>
          <Grid container spacing={2}>
            {
              config.fields.map((x, index)=>
                <Field key={index} {...{rootValues: formik.values, formik, data, item: item, validationSchema: props.validationSchema.fields[x.name]}} field={x} />
              )
            }
            <Grid item xs={12}>
              {!config.esConsola &&
                <Button style={{margin: 8}} variant="contained" color="primary" onClick={handleSaveClick} disabled={!formik.isValid || formik.isSubmitting} >Guardar</Button>
              }
            </Grid>
          
          </Grid>
        </CardContent>  
      </FormProvider>

    }
    </>
  );
}

export default Form;

