import React, { useEffect, useMemo, useState } from "react"
import { IconButton, InputAdornment, InputBase, TextField, Typography, Popover } from "@material-ui/core"
import { DetailWrapperGiz } from "../";
import { Error } from "@material-ui/icons";

export const TextGiz = React.memo((props) => {

  const {name, initialValue, details = false, caption, formik, placeholder, type, autoFocus, disabled, style = {}, inputProps = {}, multiline = false, rows = undefined, variant="outlined", size="medium", regExpAccept} = props;
  const error = formik && formik.errors[name];
  const hasError = Boolean(error);
  const [state, setState] = useState(() => ({inputValue: (formik.values && `${formik.values[name] == null ? "" : formik.values[name]}`) || initialValue || "", loaded: false, touched: false}));  
  const [timer, setTimer] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const InputComponent = useMemo(() => (variant === "standard") ? InputBase : TextField, [variant]);
  const re = useMemo(() => Boolean(regExpAccept) ? new RegExp(regExpAccept) : undefined, [regExpAccept]);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    //formik.values && setInputValue(formik.values[name]);
    setState(state => ({...state, loaded: true}));
    return () => clearTimeout(timer);
  }, [])

  useEffect(() => {
    if(state.loaded && state.touched){
      timer && clearTimeout(timer);
      setTimer(
        setTimeout(()=> {
          //console.log({name, inputValue });
          formik.setFieldValue(name, state.inputValue);
        }
      , 250));
    }
  }, [state.inputValue])

  if(details){
    return (
      <>
        <DetailWrapperGiz caption={caption}>{formik.values[name]}</DetailWrapperGiz>
      </>
    )
  }
   
  const handleInputChange = async (e) => {   
    //e.preventDefault(); 
    //console.log({targetvalue: e.target.value, inputValue, test: re.test(e.target.value), re});
    if (!re || (re && re.test(e.target.value))) {
      setState({...state, inputValue: e.target.value, touched: true});
    }
  }

  return (

    <div style={{ margin: 8 }}>
      {InputComponent && 
        <>
          <InputComponent
            rows={rows}
            multiline={multiline}
            autoFocus={autoFocus}
            fullWidth
            type={type}
            variant={variant}
            label={variant === "outlined" && caption}
            style={{ ...style }}
            placeholder={placeholder}
            value={state.inputValue}
            onChange={handleInputChange}
            error={hasError}
            helperText={size !== "small" && error}
            disabled={disabled} 
            inputProps={size !== "small" ? inputProps : {...inputProps, style: {...inputProps.style, padding: 0} }}
            size={size} 
            endAdornment={variant === "standard" && error && 
              <InputAdornment>
                <IconButton onClick={handlePopoverOpen}>
                  <Error style={{fill: "orange"}}/>
                </IconButton>
              </InputAdornment>  
            }
          />
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            disableRestoreFocus
          >
            <Typography style={{maxWidth:240, padding: 4}}>{error}</Typography>
          </Popover>
        </>
      }
      

    </div>

  )
})

