import React, { useEffect, useState } from "react";
import { PrefabBasicSelect, getBooleanValue, BloqueColor } from "./";

export const prefabSeleccionColor = ({ style, item, onClick }) => {
  return (
    <div style={style}>
      <PrefabBasicSelect
        onClick={onClick}
        title={item ? (item.identificacion && item.identificacion.descripcion) : "Selección Color"}
        hasHistory={item ? (item.propiedades && getBooleanValue(item.propiedades.HistorialHabilitado)) : true}
        hasInfo={item ? (item.propiedades && item.propiedades.TextoAyuda) : "Texto de ayuda"}
        content={<PrefabColors list={item && (item.propiedades.Colores ? item.propiedades.Colores : [])}/>}
      />
    </div>
  );
}

const PrefabColors = ({list}) => {

  const [value, setValue] = useState(() => ["#00eb46", "#ebdd46", "#54a07d", "#cd4703", "#ff0000", "#ff0edc"])
  
  useEffect(()=>{
    list && setValue(list.slice(0, 6).map(x => x.valor));
  }, [list])

  return (
    <div style={{fontSize: 10, padding: 1, whiteSpace: "nowrap", display: "flex", alignItems: "stretch", flexDirection: "column"}}>
      <div style={{ display: "flex", alignItems: "stretch", textAlign: "center", color: "white"}}>
        {value.map(x => 
          <BloqueColor color={x}/>
        )}
      </div>
    </div>
  )
}
