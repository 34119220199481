  export const prefabLineaBlanco = require("./EspacioBlanco.png");
  export const TextGiz = require("./caratulaTextGiz.png");
  export const SwitchGiz = require("./caratulaSwitchGiz.png");
  export const DatePicker = require("./caratulaDatePicker.png");
  export const TimePicker = require("./caratulaTimePicker.png");
  export const DateTimePicker = require("./caratulaDateTimePicker.png");
  export const SimpleSelectGiz = require("./caratulaSimpleSelectGiz.png");
  export const MultiSelectGiz = require("./caratulaMultiSelectGiz.png");
  export const NumberGiz = require("./caratulaNumberGiz.png");
  export const MultiSelectListGiz = require("./caratulaMultiSelectListGiz.png");
  export const SimpleSelectListGiz = require("./caratulaSimpleSelectListGiz.png");
