import React from "react";

export const PrefabItemSelVertical = ({checked, value, onClick, led, ledOn, ledOff, lighting}) => {

  return (
    <div onClick={onClick} style={{backgroundColor: (lighting && checked) ? "#006e81" : "unset", display: "flex", flex: 1, flexDirection: "row", color: "white", fontWeight: 600, fontSize: 11}}>
      {led && 
        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
          {checked ? ledOn : ledOff}
        </div>
      }
      <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
        {value}
      </div>
    </div>
  )
}