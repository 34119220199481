import axios from 'axios';

const API_URL = window.config.API_URL; 

export const login = (usuario, password) => {
  return axios.post(`${API_URL}/Account/Login`, {usuario, password});
}

export const reloadToken = (token) => {
  return axios.post(`${API_URL}/Account/ReLogin`, {}, {headers: {Authorization : token}});
}

export const ApiBase = (controller) => {

  const index = (token) => {
    return axios.get(`${API_URL}/${controller}`, {headers: {Authorization : token}});
  }
  
  const editForm = (id, token) => {
    return axios.get(`${API_URL}/${controller}/Edit/${id}`, {headers: {Authorization : token}});
  }
  
  const edit = (id, data, token) => {
    return axios.put(`${API_URL}/${controller}/${id}`, data, {headers: {Authorization : token}});
  }
  
  const createForm = (token) => {
    return axios.get(`${API_URL}/${controller}/Create`, {headers: {Authorization : token}});
  }
  
  const create = (data, token) => {
    return axios.post(`${API_URL}/${controller}`, data, {headers: {Authorization : token}});
  }
  
  const remove = (id, token) => {
    return axios.delete(`${API_URL}/${controller}/${id}`, {headers: {Authorization : token}});
  }
  
  return {index, editForm, edit, createForm, create, remove};
}

export const MenuSSO = (token) => {
  return axios.get(`${API_URL}/FeatureAccess/menu`, {headers: {Authorization : token}});
}

const AbmApiBase = (controller, token) => {
  
  const index = () => {
    return axios.get(`${API_URL}/${controller}`, {headers: {Authorization: token}});
  }
  
  const editForm = (id, ) => {
    return axios.get(`${API_URL}/${controller}/Edit/${id}`, {headers: {Authorization: token}});
  }
  
  const editItem = (id, data) => {
    return axios.put(`${API_URL}/${controller}/${id}`, data, {headers: {Authorization: token}});
  }
  
  const addForm = () => {
    return axios.get(`${API_URL}/${controller}/Create`, {headers: {Authorization: token}});
  }
  
  const addItem = (data) => {
    return axios.post(`${API_URL}/${controller}`, data, {headers: {Authorization: token}});
  }
  
  const removeItem = (id) => {
    return axios.delete(`${API_URL}/${controller}/${id}`, {headers: {Authorization: token}});
  }
  
  const getTable = (id) => {
    return axios.get(`${API_URL}/Tabla/GetTable/${id}`, {headers: {Authorization: token}});
  }
  
  const getTimeline = (id) => {
    return axios.get(`${API_URL}/${controller}/getTimeline/${id}`, {headers: {Authorization: token}});
  }

  return {index, editForm, editItem, addForm, addItem, removeItem, getTable, getTimeline};
}

export const useAbmApi = (controller, getState) => AbmApiBase(controller, getState().auth.token);