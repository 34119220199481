import React, { useMemo } from "react";
import { PrefabBasicFull, PrefabLayoutUI, calcHeight, Html5Svg } from "./";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
 
export const prefabHtml5 = ({ style, item, onClick }) =>{

  const height = item && ((item.propiedades && item.propiedades.Alto) ? calcHeight(item.propiedades.Alto) : 25) || "unset";
  
  return (
    <div style={style}>
      <PrefabBasicFull
        onClick={onClick}
        title={item ? (item.identificacion && item.identificacion.descripcion) : "Visualización de HTML5"}
        hasHistory={false}
        content={
          <PrefabLayoutUI 
            style={{height: height, padding: 4}}
            list={item && (item.propiedades ? [
              <HtmlDocument urlData={item.propiedades.ArchivoHtml}/>
            ] : [])}
            defaultValue = {[
              <div style={{ flexDirection: "column", display: "flex", alignItems: "center" }}>
                {<Html5Svg style={{fill: "white", textAlign: "center", marginLeft: 140, marginRight: 140 }}/>}
              </div>
            ]}
          />
        }
      />
    </div>
  );
}


const HtmlDocument = ({urlData}) => {

  const html = useMemo(()=> urlData && atob(urlData.split(";")[1].split(",")[1]) , [urlData]);


  return (
    <div>
      { ReactHtmlParser(html) }
    </div>
  )
}