import React from "react";
import { useEffect, useState } from "react"
import { MapContainer, TileLayer } from 'react-leaflet'
import { MapMarker, MapBuscadorControl, MapMarcaProvincias} from "../";


export const MapGiz = (props) => {
  const { lat, lng, point = undefined, formik, readonly = false } = props;

  const [state, setState] = useState({lat: -35.101934, lng: -63.896484, loaded: false})

  useEffect(()=>{
      const getLat = !point ? (formik && formik.values[lat]) : point.lat;
      const getLng = !point ? (formik && formik.values[lng]) : point.lng;
      setState(state => ({
        lat: (getLat && getLng) ? getLat : -35.101934,
        lng: (getLat && getLng) ? getLng : -63.896484, 
        loaded: true,
      }))  
  },[])

  const onChange = (latLng) => {
    formik && formik.setValues({...formik.values, [lat]: latLng.lat, [lng]: latLng.lng})
  }

  return (
    <>
      {state.loaded &&
        <MapContainer 
          center={[ state.lat, state.lng ]}
          zoom={13} 
          scrollWheelZoom={readonly} 
          id="map" 
          style={{width:"100%", height: 500}}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MapMarker readonly={readonly} lat={state.lat} lng={state.lng} onChange={onChange}/>
          {!readonly && <MapBuscadorControl/>}
          <MapMarcaProvincias/>
        </MapContainer>       
      }
    </>  
  )

}




