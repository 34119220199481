import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from "@material-ui/core";
import { createColumns, createValidationObject, useSlimFormik } from "../../../redux/utils";
import Select from 'react-select'
import { MultiSelectGizStyles, WrapperField, SortableListPasos, CustomProperties, WrapperComponent} from "../";

const CustomSelect = (props) => {

  return (
    <div style={{ paddingLeft: 8, flex: 1 }}>
      <Select {...props}/>
    </div>
  );
}

const getBasicFields = (fields) => {
  console.log("getBasicFields")
  return fields.filter(x => 
    x.name !== "idTipoPaso" 
    && x.name !== "campos"
  );
}

const getAllFields = (tipoPaso, basicFields) => {
  console.log("getAllFields")
  return [
    ...(tipoPaso ? basicFields.filter(x => tipoPaso.usaProps || (x.name !== "estados" && x.name !== "validacion")) : basicFields), 
    ...(tipoPaso ? tipoPaso.customProperties : []), 
    ...(tipoPaso ? tipoPaso.eventos : [])
  ];
}

const getValidationSchema = (tipoPaso, basicFields, allFields) => {
  console.log("getValidationSchema")
  return !tipoPaso 
  ? createValidationObject({fields: basicFields})
  : createValidationObject({fields: allFields});
}

const initialStateValue = (formik, name, order) => {
  const value = formik.values[name];
  return {list: Array.isArray(value) ? value.sortByProp(order) : value, loaded: true, open: false, newId: 0, isNew: false, tipoPaso: undefined, touchedList: false};
}

const newItemValues = (item, data, name, caption, fields, order, newId) => {
  return {
    ...fields.reduce((p, c) => {return {...p, [c.name]: c.defaultValue && eval(c.defaultValue)({item, data, name, caption, fields})};}, {}),
    id: 0,
    [order]: newId,
  } 
};

export const ObjectListPasos = (props) => {

  const { rootValues, details, name, caption, data, item, fields, formik, order } = props;
  const [panelActivo, setPanelActivo] = useState(null)
  const [state, setState] = useState(()=>initialStateValue(formik, name, order));

  useEffect(() => {  
    const value = formik.values[name];
    console.log(["cambio la lista desde afuera", value, state.list])
    if(value !== state.list){
      console.log(["cambio la lista desde afuera y son de distinta instancia", value])
      setState(state => ({...state, list: Array.isArray(value) ? value.sortByProp(order) : value}));
    }
  }, [formik.values, name, order])
  
  useEffect(() => {
    if(state.touchedList){
      formik.setFieldValue(name, state.list);
    }
  }, [state.list, state.touchedList])

  const handleAddItem = useCallback(() => {
    setPanelActivo(null);
    setState(state => ({
      ...state, 
      open: true, 
      isNew: true, 
      tipoPaso: undefined, 
      initialValues: newItemValues(item, data, name, caption, fields, order, state.newId) 
    }));
  }, [item])

  const handleEditClick = useCallback((params, event) => {
    const selectedTipoPaso = data.tiposPaso.find(x => x.value === params.row.idTipoPaso);
    event && event.preventDefault();
    setState(state => ({...state, tipoPaso: selectedTipoPaso, open: true, isNew: false, initialValues: params.row }));
  }, [])

  const handleDeleteClick = useCallback(({row}) => {
    setState(state => {
      const list = state.list.filter(x => x[order] !== row[order]);      
      return {...state, list, touchedList: true}
    });
  }, [])

  const handleClose = useCallback(() => {
    setState(state => ({...state, open: false}));
  }, [])

  const handleReorderList = useCallback((list) => {
    const list2 = list.map((x, index) => ({...x, [order]: index}));
    setState(state => ({...state, list: list2, touchedList: true}));
  }, [])

  const handleDialogSubmit = useCallback((values) => {
    setState(state => {
      const list = (
        state.isNew 
        ? [...state.list ? state.list : [], values] 
        : state.list.map(x => x[order] === values[order] ? values : x)
      ).map((x, index) => ({...x, [order]: index}));

      const lastIndex = list.length-1;
      state.isNew &&  (list[lastIndex].idTipoPaso === 1 || list[lastIndex].idTipoPaso === 2) && setPanelActivo(lastIndex);

      return {...state, open: false, list, newId: state.isNew ? state.newId - 1 : state.newId, touchedList: true}
    });
  }, [])

  const columns = useMemo(
    ()=>createColumns({fields, disabled: details})({onEdit: handleEditClick, onDelete: handleDeleteClick})
  , [fields, details, handleEditClick, handleDeleteClick]);

  return (
    <div style={{margin: "-8px 8px 8px 8px"}}>
      <SortableListPasos 
        order={order}
        data={data}
        details={details}
        onReorderList={handleReorderList} 
        rows={state.list} 
        columns={columns}
        addItem={<Button fullWidth variant="text" onClick={handleAddItem}>Agregar Paso</Button>}
        panelActivo={panelActivo}
        setPanelActivo={setPanelActivo}
        listCamposProps={{...{validationSchema: props.validationSchema && props.validationSchema.innerType.fields.campos, rootValues, data, fields: fields.find(x=>x.name === "campos").fields, initialValues: null, onChange: handleReorderList}}}
      />
      {state.open &&
        <DialogNewItem {...{rootValues, name, item, data, fields, handleClose, tipoPaso: state.tipoPaso, isNew: state.isNew, onDialogSubmit: handleDialogSubmit, initialValues: state.initialValues, newId: state.newId}}/>    
      }
    </div>
  );

}

const DialogNewItem = (props) => {

  const {rootValues, name, item, data, fields, tipoPaso, handleClose, isNew, onDialogSubmit, initialValues} = props

  const [state, setState] = useState({tipoPaso});

  const basicFields = useMemo(() => getBasicFields(fields), [fields]);
  const allFields = useMemo(() => getAllFields(state.tipoPaso, basicFields), [state.tipoPaso, basicFields]);
  const validationSchemas = useMemo(() => getValidationSchema(state.tipoPaso, basicFields, allFields), [state.tipoPaso, basicFields, allFields]);


  const fmk = useSlimFormik({
    initialValues: initialValues,
    //validationSchema: props.validationSchema && props.validationSchema.innerType,
    onSubmit: values => {
      onDialogSubmit(values);
    },
    onChange: values => {
      fmk.validate();
    }
  })

  useEffect(()=>{
    fmk.setValidationSchema(validationSchemas);
    fmk.validate();
  },[validationSchemas, fmk]);

  const handleTipoPasoChange = (selectedTipoPaso) => {
    fmk.setValues({...fmk.values, idTipoPaso: selectedTipoPaso.value})
    setState({...state, tipoPaso: selectedTipoPaso });
  } 

  return (

    <Dialog fullWidth onClose={handleClose} open={true} keepMounted={false} maxWidth="sm">
      <DialogTitle>{isNew ? `Agregar paso` : `Editar paso`} </DialogTitle>
      <DialogContent>
        <Box>
          {isNew  
            ? <Grid container>
                <WrapperField fieldProps={{
                  field: {caption: "Tipo de paso"},
                  
                  isMulti: false,
                  options: data.tiposPaso,
                  value: state.tipoPaso,
                  onChange: value => handleTipoPasoChange(value),
                  placeholder: "Seleccione el tipo de paso",
                  styles: MultiSelectGizStyles.standard(),
                  menuPortalTarget: document.body,
                  menuPosition: 'fixed',
                }} Field={CustomSelect}  />
            </Grid>
            : <Grid container>
                <WrapperComponent caption="Tipo de paso" Field={<Typography>{state.tipoPaso && state.tipoPaso.label}</Typography>} />
            </Grid>
          }
        </Box>
        {state.tipoPaso &&
          <CustomProperties validationSchema={validationSchemas} spacing={0} Wrapper={WrapperField} {...{rootValues, data, name, item, fields: allFields}} initialValues={fmk.values} onChange={fmk.setValues} validate={false}/>
        }

        {!state.tipoPaso &&
          <CustomProperties fake={true} spacing={0} Wrapper={WrapperField} item={item} fields={basicFields}/>
        }
        <DialogContentText>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button disabled={!fmk.isValid} onClick={fmk.submitForm} color="primary">
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>

  )
}