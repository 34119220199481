import React from "react";
import { PrefabBasicLarge, getBooleanValue } from "./";

const IconHTML = ({style}) => {
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" style={style}><path d="M 12 0 C 10.90625 0 10 0.90625 10 2 L 10 4 L 4 4 C 2.839844 4 2 4.839844 2 6 L 2 13 L 48 13 L 48 6 C 48 4.839844 47.160156 4 46 4 L 40 4 L 40 2 C 40 0.90625 39.09375 0 38 0 L 36 0 C 34.90625 0 34 0.90625 34 2 L 34 4 L 16 4 L 16 2 C 16 0.90625 15.09375 0 14 0 Z M 12 2 L 14 2 L 14 8 L 12 8 Z M 36 2 L 38 2 L 38 8 L 36 8 Z M 2 15 L 2 46 C 2 47.160156 2.839844 48 4 48 L 46 48 C 47.160156 48 48 47.160156 48 46 L 48 15 Z M 12 21 L 17 21 L 17 26 L 12 26 Z M 19 21 L 24 21 L 24 26 L 19 26 Z M 26 21 L 31 21 L 31 26 L 26 26 Z M 33 21 L 38 21 L 38 26 L 33 26 Z M 12 28 L 17 28 L 17 33 L 12 33 Z M 19 28 L 24 28 L 24 33 L 19 33 Z M 26 28 L 31 28 L 31 33 L 26 33 Z M 33 28 L 38 28 L 38 33 L 33 33 Z M 12 35 L 17 35 L 17 40 L 12 40 Z M 19 35 L 24 35 L 24 40 L 19 40 Z M 26 35 L 31 35 L 31 40 L 26 40 Z M 33 35 L 38 35 L 38 40 L 33 40 Z"></path></svg>
}

export const prefabFecha = ({ style, item, onClick }) =>{

  return (
    <div style={style}>
      <PrefabBasicLarge
        onClick={onClick}
        title={item ? (item.identificacion && item.identificacion.descripcion) : "Fecha"}
        hasHistory={item ? (item.propiedades && getBooleanValue(item.propiedades.HistorialHabilitado)) : true}
        hasInfo={item ? (item.propiedades && item.propiedades.TextoAyuda) : "Texto de ayuda"}
        iconHTML={<div style={{textAlign: "center"}}><IconHTML style={{fill: "#0c97c0", width: 14, height: 14}}/></div>}
        content={
          <div style={{
              textAlign: "right", 
              backgroundColor: "#efeeec", 
              borderRadius: 3, 
              color: "#1b1b1a", 
              fontSize: 8, 
              overflow: "hidden", 
              padding: 4, 
              whiteSpace: "nowrap",
              fontWeight: 700,
              height: 18,
            }}
          >
            DD/MM/YYYY
          </div>
        }
      />
    </div>
  );
}