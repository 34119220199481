
const toStringKeyObjectFn = function(obj){
  return obj ? Object.values(obj).map(x => x ? JSON.stringify(x).replace(/[^A-Za-z0-9]/g,"_") : "").join("_") : "_";
}

Array.prototype.groupBy = function(toGroupFn, toResultFn) {
  const dictionary = this.reduce(function(result, item) {
    const cGroupObject = toGroupFn(item);
    const cKeyGroupObject = toStringKeyObjectFn(cGroupObject);
    const rItem = result[cKeyGroupObject] 
      ? { 
        ...result[cKeyGroupObject], 
        items: [...result[cKeyGroupObject].items, item]
      }
      : {
        group: cGroupObject, 
        items: [item] 
      }
    return {...result, [cKeyGroupObject]: rItem};
  }, {});
  return Object.values(dictionary).map(x => toResultFn(x.group, x.items));
};

Array.prototype.sortByProp = function (prop){
  return this.sort(function (a, b){
  	return a[prop] < b[prop] ? -1 : ( a[prop] > b[prop] ? 1 : 0);
  })
}
