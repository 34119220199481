import React, { useEffect, useState } from "react";
import { Avatar, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Switch } from "@material-ui/core";

export const ObjectListTareas = (props) => {

  const { name, data, formik, order } = props;

  const [state, setState] = useState({open: false, list: [], newId: 0, isNew: false, tipoPaso: undefined, touchedList: false});

  useEffect(() => {
    //console.log({propsvalidationSchema: props.validationSchema, fields: fields, value: formik.values[name], data});
    const value = formik.values[name] || data.tareas.map(x => ({...x, checked: x.required, executeRequired: x.required}));

    const list = Array.isArray(value) ? value.sortByProp(order) : value;

    setState(state => ({...state, list}));

    ///!formik.values[name] && formik.setFieldValue(name, list);

  }, [])

  useEffect(() => {
    //if(state.touchedList){
    formik.setFieldValue(name, state.list);
    //}
  }, [state.list])


  const handleExecuteRequiredItem = (index) => {
    const list2 = state.list.map((x, i) => i !== index ? x : {...x, executeRequired: !x.executeRequired, checked: !x.executeRequired || x.checked });
    setState({...state, list: list2, touchedList: true});
  }

  const handleCheckItem = (index) => {
    const list2 = state.list.map((x, i) => i !== index ? x : {...x, checked: !x.checked, executeRequired: !x.checked && x.executeRequired});
    setState({...state, list: list2, touchedList: true});
  }

  return (
    <div style={{margin: "-8px 8px 8px 8px"}}>
      <List >
        {state.list && state.list.map((item, index) =>
          <ListItem key={index} style={{border: "1px solid #d2d2d2"}}>
            <ListItemAvatar>
              <Avatar style={{ width: 30, height: 30 }}>
                {index + 1}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              name="text"
              primary={item.label}
              secondary={
                <>
                  <span>{item.required ? "requerido" : "opcional"}</span> <br/>
                  <span>Ejecución obligatoria</span>
                  <Switch
                    disabled={!item.checked}
                    checked={item.executeRequired}
                    onChange={()=>handleExecuteRequiredItem(index)}
                  />   
                </>
              }
            />
            <ListItemSecondaryAction>
              {!item.required &&
                <Switch          
                  checked={item.checked}
                  onChange={()=>handleCheckItem(index)}
                />            
              }
            </ListItemSecondaryAction>
          </ListItem>
        )}
      </List>
    </div>
  );
}