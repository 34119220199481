import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@material-ui/core";
import { createColumns, createValidationObject, useSlimFormik } from "../../../redux/utils";
import { WrapperField, SortableListCampos, CustomProperties } from "../";
import * as images from "../../assets/images/prefabs";
import * as prefabs from "../prefabs";

const getTipoCampoStr = (item) => {
  console.log("getTipoCampoStr")
  return item.idTipoPaso && (item.idTipoPaso === 2 ? "T" : (item.idTipoPaso === 1 ? "C" : ""));
}

const getTiposCampo = (tipoCampoStr, data) => {
  return data.tiposCampo.filter(x => x.type === tipoCampoStr).groupBy((x) => ({name: x.category}), (category, group) => ({name: category.name, group})) ;
}

const getFieldsObj = (selTipoCampo, fields) => {
  const f = [...fields, ...(selTipoCampo ? selTipoCampo.customProperties : [])];
  const ff = selTipoCampo 
    ? f.filter(x => x.name !== "idTipoCampo")
      //.filter(x => selTipoCampo.aplicaValidacion ? true : x.name !== "validacion")
    : f.filter(x => x.name !== "idTipoCampo");
  return { allFields: f, fields: ff }; 
}

const getFieldsValues = (fields, prevValues, item, data, name, caption, idTipoCampo) => {
  console.log("getFieldsValues")
  return {...prevValues, ...fields.reduce((prev, c) => ({
    ...prev,
    [c.name]: (c.fields && c.type.substring(0,6) !== "Object") 
      ? getFieldsValues(c.fields, prevValues[c.name] || {}, item, data, name, caption, idTipoCampo) 
      : prevValues[c.name] 
        ? prevValues[c.name] 
        : c.defaultValue 
          ? eval(c.defaultValue)({item, data, name, caption, fields}) 
          : null
  }), {}), idTipoCampo}
}

const initialStateListValue =({campos, name, order})  =>{
  //const value = formik.values[name];
  return {list:  Array.isArray(campos) ? campos.sortByProp(order) : campos, newId: 0, isNew: false, touchedList: false};
}

const height = 400;

export const ObjectListCampos = (props) => {

  const { rootValues, details, name, caption, data, item, fields, onChangeItem, order = "order_index" } = props; 
  const campos = item[name];
  const [stateList, setStateList] = useState(()=>initialStateListValue({campos, name, order}));
  const [stateForm, setStateForm] = useState(()=>({open: false, isNew: false, newValues: {}, tipoCampo: undefined, validationSchema: undefined, touchedValues: false}));

  useEffect(() => {
    if(campos !== stateList)
    setStateList(stateList => ({...stateList, list: campos, touchedList: false }));
  }, [campos])

  useEffect(() => {
    if(stateList.touchedList){
      setStateList(stateList => {
        onChangeItem(stateList.list);
        return {...stateList, touchedList: false };
      });
    }
  }, [stateList.list, stateList.touchedList, name])

  const handleEditClick = useCallback((params) => {
    const selectedTipoCampo = data.tiposCampo.find(x => x.value === params.row.idTipoCampo);
    setStateForm(stateForm => ({
      ...stateForm,
      tipoCampo: selectedTipoCampo, 
      newValues: params.row,
      open: true, 
      isNew: false,
      touchedValues: false, 
    }));
  }, [])

  const handleAddItem = useCallback(() => {
    setStateForm(stateForm => ({
      ...stateForm, 
      tipoCampo: undefined, 
      newValues: {},
      open: true, 
      isNew: true, 
      touchedValues: false, 
    }));
  }, [])

  const handleDeleteClick = useCallback(({row}) => {
    setStateList(stateList => ({
      ...stateList, 
      list: stateList.list.filter(x => x[order] !== row[order]), 
      touchedList: true
    }));
  }, [])

  const handleClose = useCallback(() => {
    setStateForm(stateForm => ({...stateForm, open: false, newValues: {}, }));
  }, [])

  const handleReorderList = useCallback((list) => {
    setStateList(stateList => ({
      ...stateList, 
      list: list.map((x, index) => ({...x, [order]: index})), 
      touchedList: true
    }));
  }, [])

  const handleDialogSubmit = useCallback((values) => {
    setStateList(stateList => {
      const list = (
          stateForm.isNew 
            ? [...stateList.list ? stateList.list : [], values] 
            : stateList.list.map(x => x[order] === values[order] ? values : x)
        )
        .map((x, index) => ({...x, [order]: index}));       
      return {
        ...stateList, 
        list, 
        newId: stateForm.isNew ? stateList.newId - 1 : stateList.newId, 
        touchedList: true, 
        touchedValues: false
      }
    });
    handleClose();
  }, [stateForm.isNew])

  const columns = useMemo(()=>createColumns({fields, disabled: details})({onEdit: handleEditClick, onDelete: handleDeleteClick}), [handleEditClick, handleDeleteClick]);

  return (
    <div style={{margin: "8px"}}>
        <>
          Campos
          {/*<DataGridIndex components={{Toolbar: ()=><></>}} fileName={caption} rows={state.list} columns={createColumns({fields})({onEdit: handleEditClick, onDelete: handleDeleteClick})}/>*/}
          <SortableListCampos
            caption={caption}
            order={order}
            data={data}
            details={details}
            onReorderList={handleReorderList} 
            rows={stateList.list} 
            columns={columns}
            addItem={handleAddItem}
          />
            {stateForm.open && 
              <DialogNewItem {...{rootValues, caption, name, item, data, fields, handleClose, onDialogSubmit: handleDialogSubmit, stateForm, setStateForm}}/>    
            }
        </>
    </div>
  );

}
  const fieldsValues = (fields, values, tipoCampo, item, data, name, caption ) => getFieldsValues( fields, values || {}, item, data, name, caption, tipoCampo ? tipoCampo.value : null );
  
const DialogNewItem = ({rootValues, caption, name, item, data, fields, handleClose, onDialogSubmit, stateForm, setStateForm} ) => {

  const tipoCampoStr = useMemo(()=>getTipoCampoStr(item), [item]);
  const tiposCampo = useMemo(()=>getTiposCampo(tipoCampoStr, data), [tipoCampoStr, data]);
  const fieldsObj = useMemo(()=>getFieldsObj(stateForm.tipoCampo, fields), [stateForm.tipoCampo, fields]);
  
  const validationSchema = useMemo(()=> {
      console.log("createValidationObject")
      return createValidationObject({fields: fieldsObj.allFields})
  }, [fieldsObj.allFields]);


  const [loaded, setLoaded] = useState(false);

  const fmk = useSlimFormik({
    initialValues: stateForm.newValues,
    onSubmit: values => {
      setLoaded(false);
      onDialogSubmit(values);
    },
    onChange: values => {
      if(stateForm.touchedValues && loaded){
        fmk.validate();
      }
    }
  })
    
  useEffect(()=>{
    setLoaded(true);
  }, [])

  useEffect(()=>{
    if(validationSchema){
      fmk.setValidationSchema(validationSchema);
    }
  }, [validationSchema])

  /*
  useEffect(()=>{
    fmk.setValues(fieldsValues);
    setLoaded(true);
  }, [fieldsValues])
  */

  const handleChangeValues = (values) => {
    setStateForm({...stateForm, touchedValues: true });
    fmk.setValues({...values, idTipoCampo: stateForm.tipoCampo.value});  
  }

  const handleTipoCampoChange = useCallback((selectTipoCampo) => {
    //setLoaded(false);
    fmk.setValidationSchema(null);
    fmk.setValues(fieldsValues(fields, fmk.values, selectTipoCampo, item, data, name, caption));
    setStateForm(stateForm => ({...stateForm, tipoCampo: selectTipoCampo}));
  }, [fields, item, data, name, caption] )

  return (
    <Dialog fullWidth onClose={handleClose} open={loaded} keepMounted={false} maxWidth="md">
      <DialogTitle>{stateForm.isNew ? `Agregar campo` : `Editar campo`} </DialogTitle>
      <DialogContent>
        <Box margin={2}>
          <Grid container>
            <Grid item xs={5}>
              <Typography variant="h6">Tipos de campos</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="h6">{stateForm.tipoCampo && stateForm.tipoCampo.title}</Typography>
            </Grid>
            <Grid item xs={5}>
              <div className="noselect" style={{ width: 369, height: height, overflowX: "hidden", overflowY: "auto"}} >
                {
                  tiposCampo && tiposCampo.map( item => 
                    <>
                      <span><b>{item.name}</b></span>
                      <>
                        {item.group.map( item => {
                          const Prefab = prefabs[item.image];
                          return (
                            <>
                            
                              {
                              Prefab ?
                                <Prefab
                                  style={{padding: 4, backgroundColor: tipoCampoStr === "T" ? "#393a39" : "#fdfdfd", width:"100%", border: `3px solid ${(stateForm.tipoCampo && stateForm.tipoCampo.value === item.value) ? "var(--color-principal)" : "white"}`, objectFit: "scale-down"}}
                                  onClick={() => handleTipoCampoChange(item)}
                                />
                              :
                                <img
                                  key={item.value}
                                  src={images[item.image]}
                                  alt={item.label}
                                  draggable={false}
                                  onClick={() => handleTipoCampoChange(item)}
                                  style={{padding: 4, backgroundColor: tipoCampoStr === "T" ? "#393a39" : "#fdfdfd", width:"100%", border: `3px solid ${(stateForm.tipoCampo && stateForm.tipoCampo.value === item.value) ? "var(--color-principal)" : "white"}`, objectFit: "scale-down"}}
                                />
                              }
                            </>
                          );
                        })}
                      </>
                    </>
                  )
                }
              </div>
            </Grid>
            <Grid item xs={7} style={{maxHeight: height, height: height, overflowY: "scroll", overflowX: "hidden"}}>
              { stateForm.tipoCampo && 
                <>
                  <CustomProperties validationSchema={validationSchema} spacing={0} Wrapper={WrapperField} {...{rootValues, data, name, item, fields: fieldsObj.fields}} initialValues={fmk.values} onChange={handleChangeValues} validate={false}/>              
                </>
              }
            </Grid>  
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button disabled={!loaded || !fmk.isValid} onClick={fmk.submitForm} color="primary">
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}