import React from "react";
import { PrefabBasicLarge, getBooleanValue } from "./";

const IconHTML = ({style}) => {
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={style}><path d="M 12 2 C 10.343 2 9 3.343 9 5 L 9 11 C 9 12.657 10.343 14 12 14 C 13.657 14 15 12.657 15 11 L 15 5 C 15 3.343 13.657 2 12 2 z M 5 11 C 5 14.525296 7.6093644 17.433226 11 17.919922 L 11 21 L 13 21 L 13 17.919922 C 16.390636 17.433226 19 14.525296 19 11 L 17 11 C 17 13.761 14.761 16 12 16 C 9.239 16 7 13.761 7 11 L 5 11 z"></path></svg>
}

export const prefabTextoCompacto = ({ style, item, onClick }) =>{

  return (
    <div style={style}>
      <PrefabBasicLarge
        onClick={onClick}
        title={item ? (item.identificacion && item.identificacion.descripcion) : "Texto al lado"}
        hasHistory={item ? (item.propiedades && getBooleanValue(item.propiedades.HistorialHabilitado)) : true}
        hasInfo={item ? (item.propiedades && item.propiedades.TextoAyuda) : "Texto de ayuda"}
        iconHTML={<div style={{textAlign: "center"}}><IconHTML style={{fill: "#0c97c0", width: 14, height: 14}}/></div>}
        content={
          <div style={{width: "100%", backgroundColor: "#efeeec", borderRadius: 3, color: "#9e9a94", fontSize: 10, fontStyle: "italic", overflow: "hidden", padding: 1, whiteSpace: "nowrap"}}>
            Ingrese texto
          </div>
        }
      />
    </div>
  );
}