import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Field } from "../";
import { createValidationObject } from '../../../redux/utils';
import { Box, Grid, Typography } from "@material-ui/core";

export const CustomProperties = (props) => {

  const [loaded, setLoaded] = useState(false);
  const validationSchema = props.validationSchema || createValidationObject({fields: props.fields});
  const parentFormikSubmitCount = props.formik && props.formik.submitCount;
  const { onChange, formik, fieldGroups = undefined, name, item = {}, fields, initialValues = undefined, } = props;

  const localFormik = useFormik({
    initialValues: props.initialValues ? props.initialValues : {},
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnMount: true,
  }) ;

  useEffect(()=>{

    //console.log(props)
    if(!initialValues){
      const values = fields.reduce((p, c) => {
        const subitem = item && item[name] && item[name].filter(x => x.propiedad.nombre.toLowerCase() === c.name.toLowerCase());
        return subitem && subitem.length > 0 ? {...p, [c.name]: subitem[0].valor} : p;
      }, {});
      localFormik.setValues(values);  
    }
    setLoaded(true);
  }, []);

  useEffect(()=>{
    if(parentFormikSubmitCount > 0){
      localFormik.submitForm();
    }
  }, [parentFormikSubmitCount]);

  useEffect(()=>{
    if(loaded){
      formik && formik.setFieldValue(name, localFormik.values);
      onChange && onChange(localFormik.values);  
    }
  }, [localFormik.values]);
  
  
  return (
    <>
      { 
          fieldGroups
          ? fieldGroups.map((x, index) => 
            <FieldGrid key={index} {...{...props, formik: localFormik, category: x.name, fields: x.group, validationSchema, loaded}} />   
          )
          : <FieldGrid {...{...props, formik: localFormik, validationSchema, loaded}}/>   

      }
    </>
  );
}



const FieldGrid = ({name, fake, loaded, spacing, formik, data, item, fields, details, Wrapper, rootValues, category, validationSchema}) => {

  //console.log({name, category, validationSchema, fields});
   
  return (
    <>
      {category &&
        <Box style={{
          height: 34,
          padding: "6px 0px 0px 16px",
          backgroundColor: "#d7d7d7",
          borderBottom: "1px solid white",
        }}>
          <Typography variant="subtitle1" style={{fontWeight: "bold"}}>{category}</Typography>
        </Box>    
      }
      <Grid container spacing={spacing}>
        { 
          Wrapper && (!fake && loaded) &&
          fields && Array.isArray(fields) && fields.map((x, index) => <Wrapper key={index} {...{fieldProps: {rootValues, formik, data, item: item && item[name], fields: fields[name], details, field: x, validationSchema: validationSchema.fields[x.name]}, Field}} />)
        }
        {
          Wrapper && ((fake || !loaded)) && 
          fields && Array.isArray(fields) && fields.map((x, index) => <Wrapper key={index} {...{fieldProps: {field: x}, Field}} fake={true} />)
        }
        {
          !Wrapper && (!fake && loaded) &&
          fields && Array.isArray(fields) && fields.map((x, index) => <Field key={index} {...{rootValues, formik, data, item: item && item[name], fields: fields[name], details, field: x, validationSchema: validationSchema.fields[x.name]}} />)
        }
        {
          !Wrapper && (fake || !loaded) && 
          fields && Array.isArray(fields) && fields.map((x, index) => <Field key={index} fake={true} field={x} />)
        }

        {/*(!fake && loaded) &&
          fields && fields.map((x, index) => { 
            const fieldProps = {...{rootValues, formik, data, item: item && item[name], fields: fields[name], details }, field: x, validationSchema: validationSchema.fields[x.name]};
            return Wrapper ? <Wrapper key={index} {...{fieldProps, Field}} /> : <Field  key={index} {...fieldProps} />
          })
        */}
        {/*(fake || !loaded) && 
          fields && fields.map((x, index)=>{
            return Wrapper ? <Wrapper key={index} {...{fieldProps: {field: x}, Field}} fake={true} /> : <Field key={index} fake={true} field={x} />;
          })
        */}
      </Grid>
    </>
  );
}