import React from "react";
import { PrefabBasicFull, getBooleanValue, PrefabNum } from "./";

export const prefabNumeroCompleto = ({ style, item, onClick }) =>{
  return (
    <div style={style}>
      <PrefabBasicFull
        onClick={onClick}
        title={item ? (item.identificacion && item.identificacion.descripcion) : "Numérico debajo"}
        hasHistory={item ? (item.propiedades && getBooleanValue(item.propiedades.HistorialHabilitado)) : true}
        hasInfo={item ? (item.propiedades && item.propiedades.TextoAyuda) : "Texto de ayuda"}
        hasMic={true}
        content={<PrefabNum style={{marginTop: 4, marginBottom: 4}}/>}
      />
    </div>
  );
}