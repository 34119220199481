import React from "react";
import { PrefabBasicFull, getBooleanValue, PrefabSeleccionSINONA } from "./";

export const prefabSeleccionSINONACompleta = ({ style, item, onClick }) =>{
  return (
    <div style={style}>
      <PrefabBasicFull
        onClick={onClick}
        title={item ? (item.identificacion && item.identificacion.descripcion) : "Selección SI/NO/NA debajo"}
        hasHistory={item ? (item.propiedades && getBooleanValue(item.propiedades.HistorialHabilitado)) : true}
        hasInfo={item ? (item.propiedades && item.propiedades.TextoAyuda) : "Texto de ayuda"}
        hasMic={false}
        content={<PrefabSeleccionSINONA style={{paddingLeft: 120, paddingRight: 24, marginTop: 4, marginBottom: 4}}/> }
      />
    </div>
  );
}