import { IconButton } from "@material-ui/core";
import { Delete, Edit, ListAlt, FileCopy, Timeline, LocationOn, CloudDownload } from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import { useRef } from "react";
import * as yup from "yup";
import * as helpers from '../app/helpers';

export const toDispatch = (type, payload) => ({type,payload});

export const baseAction = ( {fn, REQUEST, SUCCESS, FAILURE, getState, dispatch}) => { 
  console.log({fn, REQUEST, SUCCESS, FAILURE, getState, dispatch});
  dispatch(toDispatch(REQUEST));
  return new Promise((resolve, reject) => {
    fn()
    .then( response => {
      const data = response.data.value || response.data;
      console.log(dispatch(toDispatch(SUCCESS, data)));
      resolve(data);
    })
    .catch( error => {
      //console.log(error);
      dispatch(toDispatch(FAILURE, error));
      reject(error);
    });
  })
}

export const decodeToken = (token) => {
  try {
    if (token.split(".").length !== 3 || typeof token !== "string") {
      return null;
    } else {
      var payload = token.split(".")[1];
      var base64 = payload.replace("-", "+").replace("_", "/");
      var decoded = JSON.parse(atob(base64));
      return decoded;
    }
  } catch (error) {
    return null;
  }
}

export const dias = {
  dom: {text: "DOMINGO", value: "dom", active: true},
  lun: {text: "LUNES", value: "lun", active: true},
  mar: {text: "MARTES", value: "mar", active: true},
  mie: {text: "MIERCOLES", value: "mie", active: true},
  jue: {text: "JUEVES", value: "jue", active: true},
  vie: {text: "VIERNES", value: "vie", active: true},
  sab: {text: "SABADO", value: "sab", active: true},
}

export const currencyFormatter = new Intl.NumberFormat('es-AR', {
  style: 'currency',
  currency: 'ARS',
});

export const price = {
  type: 'number',
  valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
  cellClassName: 'font-tabular-nums',
};

export const useFocus = () => {
  const htmlElRef = useRef(null)
  const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}
  return [ htmlElRef, setFocus ] 
}

const yup2 = {
  ...yup,
  N: () => yup.number(),
  TextGiz: () => yup.string().nullable(),
  NumberGiz: () => yup.number().nullable(),   
  SimpleSelectGiz: () => yup.string().nullable(),
  EmailGiz: () => yup.string().nullable().email("El email ingresado es inválido."),
}


/**
 * 
 * @param {*} param0 [{name: string, type: string, [required: bool, min: number, max: number]}]
 * @returns yup.ObjectSchemaConstructor
 */
 export const createValidationObject = ({fields = [], validationType = "object"}) => {

  const resolveType = (params) => {
    const { type } = params;
    return {...params, field: yup2[type] ? yup2[type]() : yup.string().nullable()};
  }

  const resolveRequired = (params) => {
    const { field, validation } = params;
    return {...params, field: !validation.required ? field.optional() : field.required("El dato es requerido.")};
  }

  const resolveMin = (params) => {
    const { field, type, validation } = params;
    return {...params, field: !validation.min ? field : field.min( validation.min, type === "TextGiz" ? `Debe ingresar al menos ${validation.min} caracteres` : `El valor mínimo es ${validation.min}` )};
  }

  const resolveMax = (params) => {
    const { field, type, validation } = params;
    return {...params, field: !validation.max ? field : field.max( validation.max, type === "TextGiz" ? `Puede ingresar hasta ${validation.max} caracteres` : `El valor máximo es ${validation.max}` )};
  }

  const resolveRegExp = (params) => {
    const { field, validation } = params;
    return {...params, field: !validation.regExp ? field : field.matches(new RegExp(validation.regExp), validation.regExpError)};
  }

  const createValidationField = (params) => {
    const { validation } = params;

    return (validation 
    ? resolveRegExp(
      resolveMax(
        resolveMin( 
          resolveRequired( 
            resolveType( params  )
          )
        )
      ) 
    )
    : resolveType( params )).field;
  }
  
  const validations = fields && fields.reduce((prev, x) => (
    {
      ...prev, 
      [x.name]: x.fields 
        ? createValidationObject({fields: x.fields, validationType: (x.type.substring(0,6) !== "Object") ? "object" : "array"}).nullable() 
        : createValidationField({...x})
    }
  ), {});

  //console.log({mensaje: "creando validacion " + validationType, validations})
  return validationType === "object" ? yup.object(validations) : yup.array(yup.object(validations)).nullable();
}

export const createConfig = (featureAccess) => {
  console.log(featureAccess)
  const resolveFeature = (feature, controllers) => {
    console.log({feature})
    if(feature.features != null){
      resolveSection(feature, controllers);
    }else{
      controllers.push({...feature, columns: createColumns(feature), name: feature.name , caption: feature.caption});
    }
  }

  const resolveSection = (section, controllers) => {
    console.log({section})
    section && section.features && section.features.forEach( x => resolveFeature(x, controllers));
  }

  const calc = () => {
    let controllers = [];
    if(featureAccess && featureAccess.menu) {
      featureAccess.menu.sectionRoot && resolveFeature(featureAccess.menu.sectionRoot, controllers);
      featureAccess.menu.sections && featureAccess.menu.sections.forEach(x => resolveFeature(x, controllers));
    } 
    return {controllers};
  }
  return calc();
}

export const createColumns = (feature) => ({onEdit, onDelete, onDetails, onCopy, onTimeline, onMap, extraActions, extraData}) => {
  const { fields, disabled = false } = feature;
  let columns = !fields ? [] : fields.filter(x => x.list).map(x => {
      const render = Boolean(x.render) ? eval(x.render) : undefined;
      switch(x.type){
        case "SwitchGiz":{
          return {field: `boolean_${x.name}`, headerName: x.caption, width: x.columnWidth, flex: x.flex, renderCell: render ? (params) => render(params, extraData) : (params) => (params.row[x.name] ? "SI" : "NO")}
        }
        case "ColorGiz":{
          return {field: `color_${x.name}`, headerName: x.caption, width: x.columnWidth, flex: x.flex, renderCell: render ? (params) => render(params, extraData) : (params) => (params.row[x.name] && <div dangerouslySetInnerHTML={{ __html: `<div style='display: flex;align-items: center;'><div style='background-color:${params.row[x.name]};color:${params.row[x.name]};border-radius:50%;width:20px;height:20px;display:inline-block' >.</div></div>` }} />)}
        }
        case "ImageGiz":{
          return {field: `image_${x.name}`, headerName: x.caption, width: x.columnWidth, flex: x.flex, renderCell: render ? (params) => render(params, extraData) : (params) => (params.row[x.name] && <div dangerouslySetInnerHTML={{ __html: `<img src='${params.row[x.name]}' style='height:80px;object-fit: scale-down;max-width: 100%;' draggable='false' ondragstart='return false;' class='unselectable'/>` }} />)}
        }
        default:{
          return {field: x.name, headerName: x.caption, width: x.columnWidth, flex: x.flex, renderCell: render ? (params) => <div dangerouslySetInnerHTML={{ __html: render(params, extraData) }} /> : undefined};
        }
      }
    }
  );
  if(columns.length > 0 && !disabled) {
    columns.push(
      { field: 'acciones', headerName: 'Acciones', width: 150, renderCell: (params) => {
        return (
          <>
            {onDetails &&
              <IconButton onClick={(event)=>onDetails(params, event)} style={{padding: 6}}>
                <ListAlt/>
              </IconButton>
            }
            {onEdit && !params.row.readOnly &&
              <IconButton onClick={(event)=>onEdit(params, event)} style={{padding: 6}}>
                <Edit/>
              </IconButton>
            }
            {onDelete && !params.row.readOnly &&
              <IconButton onClick={(event)=>onDelete(params, event)} style={{padding: 6}}>
                <Delete/>
              </IconButton>
            }
            {onCopy &&
              <IconButton onClick={(event)=>onCopy(params, event)} style={{padding: 6}}>
                <FileCopy/>
              </IconButton>
            }
            {onTimeline &&
              <IconButton onClick={(event)=>onTimeline(params, event)} style={{padding: 6}}>
                <Timeline/>
              </IconButton>          
            }
            {onMap && params.row.lat !== 0 && params.row.lng !== 0 && 
              <IconButton onClick={(event)=>onMap(params, event)} style={{padding: 6}}>
                <LocationOn/>
              </IconButton>          
            }
            {extraActions}
          </>)
      }}  
    )
  }
  return columns;
}

export const valueResolver = {
  DESCARGABLE: (value) => <IconButton target="file" download="archivo_adjunto" href={value}><CloudDownload /></IconButton>,
  TEXTO: (value) => value,
  IMAGEN: (value) => <img src={value} alt="imagen"></img>,
}

export const getFieldForm = ({type}) => {
  return helpers[type] || undefined;
}

export const getField = ({fields, name}) => {
  return fields.some(x => x.name && x.name === name) && fields.filter(x => x.name === name)[0];
}

export const useSlimFormik = ({initialValues, onChange, onSubmit, validationSchema = undefined})  => {

  const [values, setValues] = useState(() => initialValues);
  const [validation, setValidationSchema] = useState(() => validationSchema);
  const [isValid, setIsValid] = useState(() => false);
  const [enabledEvents, setEnabledEvents] = useState(() => true);
  const [loaded, setLoaded] = useState(() => false);
  const [isSubmitting, setSubmitting] = useState(() => false);
  
  useEffect(()=>{
    setLoaded(true)
  }, [])

  useEffect(()=>{
    loaded && enabledEvents && onChange && onChange(values);
  }, [values])

  useEffect(()=>{
    loaded && validation && validate();
  }, [validation])

  const setFieldValue = useCallback((name, value) => {
    setValues(values => ({...values, [name]: value}));
  }, [])
  
  const submitForm = useCallback(() => {
    onSubmit(values);
  }, [onSubmit, values]);

  const validate = useCallback(() => {
    validation && setIsValid(validation.isValidSync(values))
  }, [setIsValid, validation, values]);
  
  return {values, setFieldValue, setValues, submitForm, validationSchema, setValidationSchema, validate, isValid, isSubmitting, setSubmitting, setEnabledEvents};
}