import React, { useState } from 'react';
import Unity, { UnityContent } from "react-unity-webgl";
import { toAbsoluteUrl } from '../../_metronic/_helpers';

const unityContent = new UnityContent(
  toAbsoluteUrl("media/unity/build.json"),
  toAbsoluteUrl("media/unity/UnityLoader.js"),{

  }
);


export const UnityTest = (props) => {

  const [text, setText] = useState("");

  const handleChangeText = (event) => {
    setText(event.target.value);
    unityContent.send("GameObject", "WriteMessage", event.target.value);
  }


  return (
    <div>
      <input type="text" value={text} onChange={handleChangeText}/>
      <Unity  unityContent={unityContent} tabIndex={-1}/>
    </div>
  );
};
