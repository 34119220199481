import React, { useEffect, useState } from "react"
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from "@material-ui/core"

export const RadioGiz = ({name, error, options = [{value:"", label: ""}], initialState, helperText, label, formik, placeholder, onChange, disabled, style = {}}) => {

  const [timer, setTimer] = useState(null);

  const [value, setValue] = useState(formik ? formik.values[name] : initialState);
  
  useEffect(() => {
    return () => clearTimeout(timer);
  }, [])
  
  useEffect(()=>{
    timer !== null && clearTimeout(timer);
    setTimer( 
      ()=>{
        formik && setTimeout(actualizarFormik, 200);
      }
    );
  }, [value])

  const actualizarFormik = () => {
    value !== formik.values[name] && formik.setFieldValue(name, value);
    onChange && onChange({target:{name, value}});  
  }

  const handleInputChange = (e) => {
    setValue(e.target.value)
  }

  return (

    <FormControl component="fieldset" style={{ margin: 8, ...style }}>
      <FormLabel component="legend">{label}:</FormLabel>
      <RadioGroup
        aria-label={label}
        name={name}
        value={value}
        onChange={onChange || (handleInputChange)}
        disabled={disabled} 
      >
        {options.filter(x=>x.label !== "").map((x) =>
          <FormControlLabel value={x.value} control={<Radio />} label={x.label} />                 
        )}
      </RadioGroup>

    </FormControl>  
  
  )
}

