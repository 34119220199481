import React from "react";
import { PrefabBasic, getBooleanValue, SwitchButton } from "./";

export const prefabSiNoCompacto = ({ style, item, onClick }) => {

  return (
    <div style={style}>
      <PrefabBasic 
        onClick={onClick}
        title={item ? (item.identificacion && item.identificacion.descripcion) : "Control encendido/apagado si/no al lado"}
        hasHistory={item ? (item.propiedades && getBooleanValue(item.propiedades.HistorialHabilitado)) : true}
        hasInfo={item ? (item.propiedades && item.propiedades.TextoAyuda) : "Texto de ayuda"}
        content={<SwitchButton/>}
      />
    </div>
  );
}          

