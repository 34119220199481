import React, { useEffect, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, InputAdornment, InputLabel, makeStyles, OutlinedInput, Avatar, InputBase, SvgIcon } from "@material-ui/core"
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';

require('prismjs/components/prism-csharp');

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
  },
  divColor: {
    minWidth: 120,
    width: "100%",
    maxWidth:"50px",
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    borderRadius: "4px",
    
  },
  icon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    maxWidth: theme.spacing(3),
    maxHeight: theme.spacing(3),
  },

  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    maxWidth: theme.spacing(4),
    maxHeight: theme.spacing(4),
    cursor: "pointer",
  },
  pre: {
    color: "white !important",
  }

}));


export const CodeGiz = React.memo(({details = false, size, name, caption, formik, initialState = "", title, readOnly = true, onChange, variant = "outlined"}) => {

  const [open, setOpen] = useState(false);
  const [timer, setTimer] = useState(null);
  const [value, setValue] = useState(()=>formik ? formik.values[name] : initialState);
  const [code, setCode] = useState(()=>formik ? formik.values[name] : initialState);
  const [loaded, setLoaded] = useState(false);
  const error = formik && formik.touched[name] && formik.errors[name];
  const hasError = Boolean(error);

  
  useEffect(()=>{
    setLoaded(true);
  }, [])

  useEffect(()=>{
    if(loaded){
      timer !== null && clearTimeout(timer);
      setTimer( 
        formik ? setTimeout(actualizarFormik, 100) : onChange({target:{name, value: value}})
      );
      return ()=>{
        clearTimeout(timer);
        setTimer(0);
      }  
    }
  }, [value])

  const actualizarFormik = () => {
    value !== formik.values[name] && formik.setFieldValue(name, value);
  }

  const handleInputChange = () => {
    setValue(code)
    handleClose();
  }
  
  const handleCodeChange = (code) => {
    setCode(code);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleOpen = (event) => {
    event.preventDefault();
    if(!details){
      setCode(value);
      setOpen(true);
    } 
  }

  const classes = useStyles();

  const InputCode = variant === "standard" ? InputBase : OutlinedInput;

  return (

    <div style={{ margin: 8 }} className="code-giz">
      <FormControl fullWidth variant={variant} className={classes.formControl}  onClick={handleOpen} error={hasError}>
        {variant === "oulined" &&
          <InputLabel  htmlFor={`${name}-${variant}-color`} shrink={value} error={hasError}>
            {caption}
          </InputLabel>
        }
        <InputCode 
          size={size}
          readOnly={true}
          id={`${name}-${variant}-color`}
          type="text" 
          endAdornment={
            <InputAdornment position="end">
              <Avatar className={classes.avatar} style={{backgroundColor: "#bdbdbd", width:20, height:20, padding: 0}} >
                <CSharpIcon style={{width:18, height:18}} className={classes.icon}/>
              </Avatar>
            </InputAdornment>
          }
          value={code ? "Script" : ""}
          label={caption}
          onClick={handleOpen}
          style={
            variant === "standard" ? {        
              border: "0px solid white",
              borderBottom: "0px solid #949494",
              borderRadius: "0px",
              cursor: "pointer"
            } : {
              cursor: "pointer"
            }
          }
        />
        {hasError &&
          <FormHelperText>{error}</FormHelperText>
        }
            {/*<div onClick={handleOpen} className={classes.divColor}><div style={{backgroundColor: value, color: "transparent", width:"100%",  height: "25px",}}>.</div></div>*/}
      </FormControl>
      <Dialog open={open}  aria-labelledby="form-dialog-title" onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle id="form-dialog-title">{caption} - Edición de código</DialogTitle>
        <DialogContent>
          <Editor
            autoFocus={true}
            value={code || ""}
            onValueChange={handleCodeChange}
            highlight={x => {
              //console.log({x, languages})
              return highlight(x, languages.csharp);
            }}
            padding={10}
            preClassName={classes.pre}
            style={{
              fontFamily: 'Consolas, "Fira code", "Fira Mono", monospace',
              fontSize: 14,
              backgroundColor: "black",
              caretColor: "white",
              height: 400,
              minHeight: 400,
              width: "100%",
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleInputChange}>Aceptar</Button>
        </DialogActions>
      </Dialog>
    </div>


  )
})

const CSharpIcon = (props) => {

  return (
    <SvgIcon {...props} viewBox="0 0 48 48" >
      <path fill="#37474f" fill-rule="evenodd"
        d="M22.903,3.286c0.679-0.381,1.515-0.381,2.193,0 c3.355,1.883,13.451,7.551,16.807,9.434C42.582,13.1,43,13.804,43,14.566c0,3.766,0,15.101,0,18.867 c0,0.762-0.418,1.466-1.097,1.847c-3.355,1.883-13.451,7.551-16.807,9.434c-0.679,0.381-1.515,0.381-2.193,0 c-3.355-1.883-13.451-7.551-16.807-9.434C5.418,34.899,5,34.196,5,33.434c0-3.766,0-15.101,0-18.867 c0-0.762,0.418-1.466,1.097-1.847C9.451,10.837,19.549,5.169,22.903,3.286z"
        clip-rule="evenodd"></path>
      <path fill="#546e7a" fill-rule="evenodd"
        d="M5.304,34.404C5.038,34.048,5,33.71,5,33.255 c0-3.744,0-15.014,0-18.759c0-0.758,0.417-1.458,1.094-1.836c3.343-1.872,13.405-7.507,16.748-9.38 c0.677-0.379,1.594-0.371,2.271,0.008c3.343,1.872,13.371,7.459,16.714,9.331c0.27,0.152,0.476,0.335,0.66,0.576L5.304,34.404z"
        clip-rule="evenodd"></path>
      <path fill="#fff" fill-rule="evenodd"
        d="M24,10c7.727,0,14,6.273,14,14s-6.273,14-14,14 s-14-6.273-14-14S16.273,10,24,10z M24,17c3.863,0,7,3.136,7,7c0,3.863-3.137,7-7,7s-7-3.137-7-7C17,20.136,20.136,17,24,17z"
        clip-rule="evenodd"></path>
      <path fill="#455a64" fill-rule="evenodd"
        d="M42.485,13.205c0.516,0.483,0.506,1.211,0.506,1.784 c0,3.795-0.032,14.589,0.009,18.384c0.004,0.396-0.127,0.813-0.323,1.127L23.593,24L42.485,13.205z"
        clip-rule="evenodd"></path>
      <path fill="#fff" fill-rule="evenodd" d="M34 20H35V28H34zM37 20H38V28H37z" clip-rule="evenodd"></path>
      <path fill="#fff" fill-rule="evenodd" d="M32 25H40V26H32zM32 22H40V23H32z" clip-rule="evenodd"></path>
    </SvgIcon>
  
  )
}