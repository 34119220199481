import React from "react";
import { PrefabBasic, BlueButton, getBooleanValue } from "./";

const ButtonIcon = ({style}) => {
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" style={style}><path d="M 3 9 L 3 41 L 5 41 L 5 9 Z M 7 9 L 7 41 L 10 41 L 10 9 Z M 12 9 L 12 41 L 20 41 L 20 9 Z M 22 9 L 22 41 L 24 41 L 24 9 Z M 26 9 L 26 41 L 29 41 L 29 9 Z M 31 9 L 31 41 L 33 41 L 33 9 Z M 35 9 L 35 41 L 39 41 L 39 9 Z M 41 9 L 41 41 L 43 41 L 43 9 Z M 45 9 L 45 41 L 47 41 L 47 9 Z"></path></svg>
}

export const prefabEscanearBarra = ({ style, item, onClick }) =>{
  return (
    <div style={style}>
      <PrefabBasic 
        onClick={onClick}
        title={item ? (item.identificacion && item.identificacion.descripcion) : "Escanear Barra"}
        hasHistory={item ? (item.propiedades && getBooleanValue(item.propiedades.HistorialHabilitado)) : true}
        hasInfo={item ? (item.propiedades && item.propiedades.TextoAyuda) : "Texto de ayuda"}
        content={
          <BlueButton>
            <ButtonIcon style={{width: 16, height: 16, fill: "white"}}/>
          </BlueButton>
        }
      />
    </div>
  );
}