import React from "react";
import { PrefabBasicFull, getBooleanValue, PrefabSeleccionMultipleOpciones, Imagen1, Imagen2, Imagen3 } from "./";
import { PrefabItemSelVertical } from "./base";

export const prefabSeleccionVerticalMultipleImagenes = ({ style, item, onClick }) =>{
  return (
    <div style={style}>
      <PrefabBasicFull
        onClick={onClick}
        title={item ? (item.identificacion && item.identificacion.descripcion) : "Selección vertical múltiple de imágenes"}
        hasHistory={item ? (item.propiedades && getBooleanValue(item.propiedades.HistorialHabilitado)) : true}
        hasInfo={item ? (item.propiedades && item.propiedades.TextoAyuda) : "Texto de ayuda"}
        hasMic={false}
        content={
          <PrefabSeleccionMultipleOpciones 
            lighting={false}
            list={item && (item.propiedades.ListaImagenes ? item.propiedades.ListaImagenes.map(x => ({...x, valor: <img style={{objectFit: "scale-down", maxWidth: "100%"}} srcSet={x.valor}/>})) : [])} 
            style={{marginTop: 4, marginBottom: 4, marginRight: 20}}
            defaultValue={[
              {checked: true, value: <Imagen1 style={{objectFit: "scale-down", maxWidth: "100%"}}/>}, 
              {checked: true, value: <Imagen2 style={{objectFit: "scale-down", maxWidth: "100%"}}/>}, 
              {checked: false, value: <Imagen3 style={{objectFit: "scale-down", maxWidth: "100%"}}/>}, 
            ]}
          />
        }
      />
    </div>
  );
}
