import React from "react";
import { PrefabBasicSelect, PrefabTituloSeleccionHorizontal } from "./";

export const prefabTituloSeleccionHorizontalCompacta = ({ style, item, onClick }) => {

  return (
    <div style={{ ...style, paddingTop: 4, paddingBottom: 4,}}>
      <PrefabBasicSelect
        onClick={onClick}
        title=""
        content={<PrefabTituloSeleccionHorizontal list={item && (item.propiedades.ListaOpciones ? item.propiedades.ListaOpciones : [])}/> }
      />
    </div>
  );
  
}