import React from 'react';
import { DataGrid, GridColumnsToolbarButton, GridDensitySelector, GridFilterToolbarButton, GridToolbarContainer } from '@material-ui/data-grid';
import { GRID_DEFAULT_LOCALE_TEXT } from '../_core/localeTextConstants';
import { ExportCSV } from './ExportCSV';

export const DataGridIndex = (props) => {
  return (
    <DataGrid   
    columns      
      {...{
        autoHeight: true,
        rowHeight:60,
        checkboxSelection: false,
        pageSize: 8,
        ...props,
        components: {
          Toolbar: (x) => <CustomToolbar  {...props} rowsExport={props.rowsExport ? props.rowsExport : props.rows}/>,
          ...props.components, 
        },
        localeText: GRID_DEFAULT_LOCALE_TEXT
      }}
    />
  );
}

const CustomToolbar = (props) => {
  return (
    <GridToolbarContainer>
        <GridColumnsToolbarButton />
        <GridFilterToolbarButton />
        <GridDensitySelector />
        <GridExportButton {...props} rows={props.rowsExport ? props.rowsExport : props.rows}  />
    </GridToolbarContainer>
  );
 }

const GridExportButton = ({rows, fileName}) => {
  return (
      <ExportCSV csvData={rows} fileName={fileName ? fileName : "exportar"} >Exportar</ExportCSV>
  );
}