import { makeStyles, Autocomplete, Box, TextField } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
    backgroundColor: "white",
  },
}));

const selectStyles = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: 1000,
  }),
  valueContainer:(provided, state) => ({
    ...provided,
    margin: "8px",
  }),
}

export const MapSelectGiz = (props) =>  {

  const {name, value, caption, items, onChange} = props;
  const classes = useStyles();

  return (
    <div  style={{ margin: 8 }}>
      <Autocomplete
        fullWidth
        className={classes.formControl}
        id={name}
        options={items}
        autoHighlight
        getOptionLabel={(option) => option}
        onChange={onChange}
        size="small"
        value={value}
        renderOption={(props, option) => (
          <Box
            component="li"
            {...props}
          >
            {option}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={caption}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
            
          />
        )}
      />
    </div>
  )
}