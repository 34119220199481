import React from "react";
import { PrefabBasicFull, getBooleanValue, PrefabSelHorizontalMultipleOpciones, PrefabTituloSeleccionHorizontal } from "./";

export const prefabSeleccionHorizontalMultipleOpcionesCompleta = ({ style, item, onClick }) =>{
  return (
    <div style={style}>
      <PrefabBasicFull
        onClick={onClick}
        title={item ? (item.identificacion && item.identificacion.descripcion) : "Selección múltiple horizontal debajo"}
        hasHistory={item ? (item.propiedades && getBooleanValue(item.propiedades.HistorialHabilitado)) : true}
        hasInfo={item ? (item.propiedades && item.propiedades.TextoAyuda) : "Texto de ayuda"}
        hasMic={false}
        content={<PrefabTituloSeleccionHorizontal list={item && (item.propiedades.ListaOpciones ? item.propiedades.ListaOpciones : [])}  style={{marginLeft: 120, marginRight: 24, marginTop: 4, marginBottom: 0}}/> }
        content2={<PrefabSelHorizontalMultipleOpciones list={item && (item.propiedades.ListaOpciones ? item.propiedades.ListaOpciones : [])}  style={{marginLeft: 120, marginRight: 24, marginTop: 0, marginBottom: 4}}/> }
      />
    </div>
  );
}