import React, { useContext, useEffect, useState } from "react";
import { useJwt } from "react-jwt";
import { reloadToken } from '../services/ApiService';

export const useAuth = () => {

  const [token, setToken] = useState("");
  const { decodedToken, isExpired, reEvaluateToken } = useJwt("");
  const [auth, setAuth] = useState({loaded: false, intentParamToken: false, isAuthenticated: false});
  const [featureAccess, setFeatureAccess] = useState(()=>{});

  useEffect(()=>{
    const tokenStorage = localStorage.getItem("token");
    if(tokenStorage){
      onAuth(tokenStorage);
    }else{
      //setLoaded(true);
    }
    
    // eslint-disable-next-line
  }, [])

  const reLogin = () => {
    const t = localStorage.getItem("token");
    if(t){
      reloadToken("Bearer " + t)
      .then(response => {
        onAuth(response.data.token);
      }).catch(error => {
        
      });
    }
  }

  useEffect(()=>{
    reEvaluateToken(token);
    // eslint-disable-next-line
  }, [token])

  useEffect(()=>{
  }, [auth.loaded])

  useEffect(()=>{
    if(decodedToken && !isExpired){
      setAuth({
        ...auth, 
        ...decodedToken,
        loaded: true,
        token: "Bearer " + token,
        isAuthenticated: true,
      });  
      localStorage.setItem("token", token);
    }else{
      setAuth({
        ...auth,
        loaded: true,
        token: "",
        isAuthenticated: false,
      });  
      localStorage.clear();
    }

    // eslint-disable-next-line
  }, [decodedToken, isExpired])
  
  const onSignOut = () => {
    setToken("");
  }

  const onAuth = (token, isParamToken) => {
    //console.log({Auth_onAuth: token})
    if(isParamToken) setAuth({...auth, intentParamToken: true});
    setToken(token);
  }

  return { auth, onAuth, onSignOut, setFeatureAccess, reLogin };
}

export const AuthContext = React.createContext(useAuth); 

export const useAuthContext = () => { 
  return useContext(AuthContext); 
};

export const AuthProvider = ({ children }) => {
  const auth = useAuth();
  
  return (
    <AuthContext.Provider value={auth}>
      {auth.auth.loaded && children}
    </AuthContext.Provider>
  );
};


