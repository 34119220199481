import React, { useEffect, useState } from "react"
import { CardContent, Button, Grid, IconButton, List, ListItemText, ListItemSecondaryAction, ListItem } from "@material-ui/core"
import { Cancel, CloudDownload } from "@material-ui/icons";

export const AttachedFileGiz = ({accept, name, details = false, caption, formik, placeholder, type, autoFocus, disabled, style = {}, inputProps = {}, multiline = false, rows = undefined, variant="outlined", size="medium"}) => {

  //const error = formik && formik.errors[name];
  const [selectedFile, setSelectedFile] = useState(null)
  const [data, setData] = useState(null)
  const [loaded, setLoaded] = useState(false);
  
  useEffect(()=>{
    formik && formik.values[name] && setData(formik.values[name]);
    setLoaded(true);
  },[])

  useEffect(()=>{
    loaded && formik && formik.values[name] !== data && formik.setFieldValue(name, data);
  },[data])

  const handleFileCancel = (event) => {
    setSelectedFile(null);
    setData(null);
  }
  
    // On file select (from the pop up) 
  const onFileChange = (event) => { 

    // Update the state 
    if(event.target.files.length > 0) {

      var reader  = new FileReader();
      reader.onloadend = function () {
        setData(reader.result)
      }
      reader.readAsDataURL(event.target.files[0]);
      setSelectedFile( event.target.files[0] ); 
      event.target.value = [ ];

    }
    
  }; 
  

  return (
    <div style={{ margin: 8 }}>

      {!selectedFile && !data &&
        <CardContent>
          <div> 
            <input 
              type="file" 
              name="imageUpload" 
              id={name}
              accept={accept}
              style={{display: "none"}} 
              onChange={onFileChange} 
            /> 
            <Button variant="contained" disabled={selectedFile !== null}><label for={name} >Seleccione un arhivo</label></Button>
          </div> 
        </CardContent>
      }


      {data &&
        <Grid container> 
          <Grid item xs={12}>
            <List>
              <ListItem style={{backgroundColor: "white", opacity: 1}}>
                <ListItemText primary="[Archivo adjunto]"/>
                {!details && 
                  <ListItemSecondaryAction>
                    <IconButton target="file" download="archivo_adjunto" href={data}><CloudDownload /></IconButton>
                    <IconButton onClick={handleFileCancel}><Cancel /></IconButton>
                  </ListItemSecondaryAction>
                }
              </ListItem>
            </List>
          </Grid>
        </Grid> 
      }


    </div>
  )
}