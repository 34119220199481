import React from "react";
import { PrefabBasicFull, getBooleanValue, PrefabSeleccionMultipleOpciones, ImagenCuerpo1, ImagenCuerpo2, ImagenCuerpo3, ImagenCuerpo4, ImagenCuerpo5, ImagenCuerpo6 } from "./";

export const prefabSeleccionHorizontalMultipleImagenes = ({ style, item, onClick }) =>{
  return (
    <div style={style}>
      <PrefabBasicFull
        onClick={onClick}
        title={item ? (item.identificacion && item.identificacion.descripcion) : "Selección horizontal múltiple de imágenes"}
        hasHistory={item ? (item.propiedades && getBooleanValue(item.propiedades.HistorialHabilitado)) : true}
        hasInfo={item ? (item.propiedades && item.propiedades.TextoAyuda) : "Texto de ayuda"}
        hasMic={false}
        content={
          <PrefabSeleccionMultipleOpciones 
            lighting={false}
            list={item && (item.propiedades.ListaImagenes ? item.propiedades.ListaImagenes.map(x => ({...x, valor: <img style={{objectFit: "scale-down", maxWidth: "100%"}} srcSet={x.valor}/>})) : [])} 
            style={{marginTop: 4, marginBottom: 4}}
            defaultValue={[
              {checked: true , value: <ImagenCuerpo1 style={{objectFit: "scale-down", maxWidth: "100%"}}/>}, 
              {checked: true , value: <ImagenCuerpo2 style={{objectFit: "scale-down", maxWidth: "100%"}}/>}, 
              {checked: false, value: <ImagenCuerpo3 style={{objectFit: "scale-down", maxWidth: "100%"}}/>}, 
              {checked: false, value: <ImagenCuerpo4 style={{objectFit: "scale-down", maxWidth: "100%"}}/>}, 
              {checked: false, value: <ImagenCuerpo5 style={{objectFit: "scale-down", maxWidth: "100%"}}/>}, 
              {checked: false, value: <ImagenCuerpo6 style={{objectFit: "scale-down", maxWidth: "100%"}}/>}, 
            ]}
            type="horizontal"
          />
        }
      />
    </div>
  );
}
