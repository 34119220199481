import React from "react";
import { Grid } from "@material-ui/core";
import { getFieldForm } from "../../../redux/utils";
import { WrapperComponent } from "../";

export const Field = (props) => {

  const { fieldFn } = props.field;
  const { rootValues, variant = "outlined", details = false, fake = false, field, formik = {}, data = {}, item = {}, validationSchema, grouping } = fieldFn ? eval(fieldFn)(props) : props;
  const controlForm = getFieldForm({type: field.type}); 
  const FieldGiz = fake && controlForm ? getFieldForm({type: "fake"}) : controlForm;

  return (
    <>
      {FieldGiz && (variant === "wrapper"
        ? <WrapperComponent 
          caption={field.caption} 
          Field={<FieldGiz {...{rootValues, formik, data, item, details, validationSchema, grouping, ...field, columns: 12, variant: "standard", size: "small"}}/>}
        />
        : <Grid item xs={12} md={field.columns}>
          <FieldGiz {...{rootValues, formik, data, item, details, validationSchema, grouping, ...field}}/>
        </Grid>    
      )}

    </>
  )
}

