import React, { useEffect, useState } from "react";
import { CustomProperties } from "../";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { useFormik } from "formik";
import { createColumns } from "../../../redux/utils";

export const ObjectListValues = (props) => {

  const { rootValues, details, name, caption, data, item, fields, formik, order = "order_index", editText, addText, SortableList } = props;
  const [state, setState] = useState({open: false, list: [], newId: 0, isNew: false, touchedList: false, touchedValues: false});

  const newItemValues = () => {
    return {
      ...fields.reduce((p, c) => ({...p, [c.name]: c.defaultValue && eval(c.defaultValue)({item, data, name, caption, fields})}), {}),
      [order]: state.newId,
    } ;
  };

  useEffect(() => {
    const value = formik.values[name] || [];
    setState(state => ({...state, list: Array.isArray(value) ? value.sortByProp(order) : [], loaded: true}));
    return () => {
    }
  }, [])

  useEffect(() => {
    if(state.touchedList){
      formik.setFieldValue(name, state.list);
      setState({...state, touchedList: false})
    }
  }, [state.list])

  const fmk = useFormik({
    initialValues: {},
    validationSchema: props.validationSchema && props.validationSchema.innerType,
    onSubmit: values => {  
      const list = (
        state.isNew 
        ? [...state.list ? state.list : [], values] 
        : state.list.map(x => x[order] === values[order] ? values : x)).map((x, index) => ({...x, [order]: index}));
      setState({...state, list, newId: state.isNew ? state.newId - 1 : state.newId, open: false, touchedList: true});
    }
  })
  
  const handleSubmit = () => {
    fmk.validateForm()
    .then( () => {
      fmk.submitForm();
    })
    .catch( error => {
      console.log(error)
    });
  }

  const handleChangeValues = (values) => {
    setState({...state, touchedValues: true });
    fmk.setValues(values);  
  }

  const handleClose = () => {
    setState({...state, open: false});
  }

  const handleEditClick = ({row}, event) => {
    event && event.preventDefault();
    fmk.setValues(row);
    setState({...state, open: true, isNew: false, touchedValues: false});
  }

  const handleAddItem = () => {
    setState({...state, open: true, isNew: true, touchedValues: false});
    fmk.setValues(newItemValues());
  }

  const handleDeleteClick = ({row}) => {
    setState({...state, list: state.list.filter(x => x[order] !== row[order]), touchedList: true});
  }

  const handleReorderList = (list) => {  
    setState({...state, list: list.map((x, index) => ({...x, [order]: index})), touchedList: true})
  }

  return (
    <div style={{margin: "8px"}}>
      {/*<DataGridIndex components={{Toolbar: ()=><></>}} fileName={caption} rows={state.list} columns={createColumns({fields})({onEdit: handleEditClick, onDelete: handleDeleteClick})}/>*/}
      <SortableList 
        data={data}
        details={details}
        onReorderList={handleReorderList} 
        rows={state.list} 
        columns={createColumns({fields, disabled: details})({onEdit: handleEditClick, onDelete: handleDeleteClick})}
        addItem={<Button fullWidth variant="text" onClick={handleAddItem}>{addText}</Button>}
      />
      
      <Dialog fullWidth onClose={handleClose} open={state.open} keepMounted={false} maxWidth="xs">
        <DialogTitle>{state.isNew ? addText : editText} </DialogTitle>
        <DialogContent>
          <CustomProperties  validationSchema={props.validationSchema && props.validationSchema.innerType} {...{rootValues, data, name, item, fields}} initialValues={fmk.values} onChange={handleChangeValues}/>
          <DialogContentText>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button disabled={!fmk.isValid} onClick={handleSubmit} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

}