import React from "react";
import { PrefabBasicFull, PrefabLayoutUI, calcHeight, PictureSvg } from "./";

export const prefabImagenImagenUI = ({ style, item, onClick }) =>{

  const height = item && ((item.propiedades && item.propiedades.Alto) ? calcHeight(item.propiedades.Alto) : 25);
  
  return (
    <div style={style}>
      <PrefabBasicFull
        onClick={onClick}
        title={item ? (item.identificacion && item.identificacion.descripcion) : "Gráfico y Gráfico al 50%"}
        hasHistory={false}
        content={
          <PrefabLayoutUI 
            style={{height: height || "unset", padding: 4}}
            list={item && (item.propiedades ? [
              <img style={{objectFit: "contain", maxWidth: "100%", maxHeight: "100%"}} src={item.propiedades.ImagenIzquierda}/>,
              <img style={{objectFit: "contain", maxWidth: "100%", maxHeight: "100%"}} src={item.propiedades.ImagenDerecha}/>,
            ] : [])}
            defaultValue = {[
              <div style={{ flexDirection: "column", display: "flex", alignItems: "center" }}>
                {<PictureSvg style={{fill: "white", textAlign: "center", marginLeft: 48, marginRight: 48 }}/>}
              </div>,
              <div style={{ flexDirection: "column", display: "flex", alignItems: "center" }}>
                {<PictureSvg style={{fill: "white", textAlign: "center", marginLeft: 48, marginRight: 48 }}/>}
              </div>,
            ]}
          />
        }
      />
    </div>
  );
}