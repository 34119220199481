import React, { useCallback, useEffect, useMemo } from 'react';
import { ListItem, ListItemText, Box, ListItemAvatar, Avatar, ListItemSecondaryAction, Accordion, AccordionSummary, AccordionDetails, IconButton, SvgIcon } from '@material-ui/core';
import { useSlimFormik } from '../../../redux/utils';
import { ObjectListCampos } from '../';
import { SortableListDnd, SortableItemDnd } from "../" ;

const itemStyle = { marginTop: "8px", backgroundColor: "#2aaede05" }

export const SortableListPasos = ({ order, listCamposProps, details, onReorderList, rows, columns, addItem, data, panelActivo, setPanelActivo }) => {

  const onChangeItem = useCallback((item) => {
    const newRows = rows.map((x) => x[order] === item[order] ? item : x);
    console.log(["cambio un item (item, rows, newrows)", item, rows, newRows])
    onReorderList(newRows);
  }, [rows])

  const onSortEnd = useCallback((newRows) => {
    setPanelActivo(null);
    onReorderList(newRows);
  }, [onReorderList]);

  const togglePanel = useCallback((panelName) => {
    setPanelActivo(panelActivo === panelName ? null : panelName);
  }, [panelActivo]);

  return (
    <Box style={{ width: "100%" }}>
      <SortableListDnd name="Pasos" onSortEnd={onSortEnd} rows={rows} >
        {({rows, items}) => 
          <div>        
            {rows && rows.map((item, index) => (
              <SortableItemDnd key={items[index]} id={items[index]} {...{item, details, order, onChangeItem, togglePanel, itemStyle, panelActivo, columns, listCamposProps, data}}>
                {(props) => 
                  <ItemList {...props}/>
                }
              </SortableItemDnd>
            ))}
            {addItem && !details && <ListItem style={{ ...itemStyle, border: "1px #2aaede dashed" }} >{addItem}</ListItem>}
          </div>
        }
      </SortableListDnd>
    </Box>
  );
}

const ItemList = ({ listeners, rootProps, order, onChangeItem, togglePanel, details, itemStyle, panelActivo, columns, listCamposProps, item, data }) => {

  const primaryText = `${data.tiposPaso.find(x => x.value === item.idTipoPaso).label} > ${item.identificacion.nombre} > ${item.identificacion.descripcion}`;
  const soportaCampos = (item.idTipoPaso === 1 || item.idTipoPaso === 2);
  const expandido = (item[order] === panelActivo) && soportaCampos;
  const listenersProps = expandido ? {} : listeners;

  const handleChangeItem = useCallback((campos) => {
    onChangeItem({...item, campos})
  }, [item])

  return (
    <div {...rootProps}>
        <Accordion elevation={0} style={{ ...itemStyle, border: "1px #2aaede solid", backgroundColor: "white", opacity: 1, boxShadow: "none" }} expanded={expandido}>
          <AccordionSummary>
            <ListItemAvatar  {...listenersProps}>
              <Avatar style={{ width: 30, height: 30 }}>
                {item[order] + 1}
              </Avatar>
            </ListItemAvatar>

            <ListItemText
              name="text"
              primary={primaryText}
              {...listenersProps}
            />
            {!details &&
              <ListItemSecondaryAction>
                {soportaCampos &&
                  <IconButton style={expandido ? {backgroundColor: "rgb(13, 159, 202)"} : {}} onClick={(event) => togglePanel(item[order])}>
                    <ThListIcon style={{width:18, height:18, color: expandido ? "white" : "" }}/>
                  </IconButton>
                }
                {columns.filter(x => x.field === "acciones")[0].renderCell({ row: item })}
              </ListItemSecondaryAction>
            }
          </AccordionSummary>

          {expandido && 
            <AccordionDetails>
                <ObjectListCampos name="campos" {...listCamposProps} item={item} onChangeItem={handleChangeItem} caption={item.identificacion.descripcion} order={order} />
            </AccordionDetails>
          }
        </Accordion>
    </div>
  )
}

const ThListIcon = React.memo(({style}) => {
  return (
    <SvgIcon style={style} viewBox="0 0 512 512" >
      <g class="fa-group">
        <path fill="currentColor"
          d="M488 352H205.33a24 24 0 0 0-24 24v80a24 24 0 0 0 24 24H488a24 24 0 0 0 24-24v-80a24 24 0 0 0-24-24zm0-320H205.33a24 24 0 0 0-24 24v80a24 24 0 0 0 24 24H488a24 24 0 0 0 24-24V56a24 24 0 0 0-24-24zm0 160H205.33a24 24 0 0 0-24 24v80a24 24 0 0 0 24 24H488a24 24 0 0 0 24-24v-80a24 24 0 0 0-24-24z"
          class="fa-secondary"></path>
        <path fill="currentColor"
          d="M125.33 192H24a24 24 0 0 0-24 24v80a24 24 0 0 0 24 24h101.33a24 24 0 0 0 24-24v-80a24 24 0 0 0-24-24zm0-160H24A24 24 0 0 0 0 56v80a24 24 0 0 0 24 24h101.33a24 24 0 0 0 24-24V56a24 24 0 0 0-24-24zm0 320H24a24 24 0 0 0-24 24v80a24 24 0 0 0 24 24h101.33a24 24 0 0 0 24-24v-80a24 24 0 0 0-24-24z"
          class="fa-primary"></path>
      </g>
    </SvgIcon>
  )
})