import React, { useMemo } from 'react';

import {
  DndContext, 
  closestCenter,
  closestCorners,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from '@dnd-kit/modifiers';
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove
} from '@dnd-kit/sortable';


export const SortableListDnd = ({name, rows, onSortEnd, onSortStart, children}) => {

  const sensors = useSensors(
    useSensor(PointerSensor)
  );

  const items = rows ? rows.map((x, index) => `${name}_${index}`) : []; 

  const handleDragEnd = (event) => {
    const {active, over} = event;
    if (active.id !== over.id) {
      const oldIndex = items.indexOf(active.id);
      const newIndex = items.indexOf(over.id);
      //console.log({rows, items, active, over, oldIndex, newIndex})
      if(oldIndex >= 0 && newIndex >= 0 ){
        onSortEnd(arrayMove(rows, oldIndex, newIndex))
      }
    }
  }

  return (
    <DndContext 
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragEnd={handleDragEnd}
      onDragStart={onSortStart}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
    >
        <SortableContext 
          items={items}
          strategy={verticalListSortingStrategy}
        >
          {children({name, rows, items})}
        </SortableContext>
    </DndContext>
  );
};

