import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { useFormik } from "formik";
import { createColumns } from "../../../redux/utils";
import { SortableList, CustomProperties } from "../";

export const ObjectList = (props) => {

  const { details, name, caption, data, item, fields, rootValues, formik, order = "order_index", maxWidth = "lg", variant = "outlined" } = props;
  const [state, setState] = useState({open: false, values: {}, list: [], newId: 0, isNew: false});

  const newItemValues = () => {
    return {
      ...fields.reduce((p, c) => ({...p, [c.name]: c.defaultValue && eval(c.defaultValue)({item, data, name, caption, fields})}), {}),
      [order]: state.newId,
    } 
  };

  useEffect(() => {
    const value = formik.values[name];
    setState({...state, list: Array.isArray(value) ? value.sortByProp(order) : value});
    return () => {
    }
  }, [])

  const fmk = useFormik({
    initialValues: {},
    validationSchema: props.validationSchema && props.validationSchema.innerType,
    onSubmit: values => {
      handleClose();
      setState((prev) => {
        const list = (prev.isNew ? [...prev.list ? prev.list : [], values] : prev.list.map(x => x[order] === values[order] ? values : x))
          .map((x, index) => ({...x, [order]: index}));
        formik.setFieldValue(name, list);
        return {...prev, list, newId: prev.isNew ? prev.newId - 1 : prev.newId};
      })
    }
  })
  
  const handleSubmit = () => {
    console.log(fmk.errors)
    fmk.validateForm()
    .then( () => {
      fmk.submitForm();
    })
    .catch( error => {
      console.log(error)
    });
  }

  const handleChangeValues = (values) => {
    console.log("ObjectList handleChangeValues");
    fmk.setValues(values);
  }

  const handleClose = () => {
    setState({...state, open: false});
  }

  const handleOpen = () => {
    setState({...state, open: true});
  }

  const handleEditClick = ({row}, event) => {
    event && event.preventDefault();
    setState({...state, open: true, values: row, isNew: false});
  }

  const handleDeleteClick = ({row}) => {
    setState((prev) => {
      const list = prev.list.filter(x => x[order] !== row[order]);
      formik.setFieldValue(name, list);
      return {...prev, list};
    })
  }

  const handleAddItem = () => {
    setState({...state, open: true, values: newItemValues(), isNew: true});
  }

  const handleReorderList = (list) => {
    setState((prev) => {
      formik.setFieldValue(name, list.map((x, index) => ({...x, [order]: index})));
      return {...prev, list};
    })
  }

  return (
    <div style={{margin: "8px"}}>

      {variant == "outlined" && caption}
      {/*<DataGridIndex components={{Toolbar: ()=><></>}} fileName={caption} rows={state.list} columns={createColumns({fields})({onEdit: handleEditClick, onDelete: handleDeleteClick})}/>*/}
      <SortableList 
        data={data}
        details={details}
        onReorderList={handleReorderList} 
        rows={state.list} 
        columns={createColumns({fields, disabled: details})({onEdit: handleEditClick, onDelete: handleDeleteClick})}
        addItem={<Button fullWidth variant="text" onClick={handleAddItem}>Agregar</Button>}
      />
      
      <Dialog fullWidth onClose={handleClose} open={state.open} keepMounted={false} maxWidth={maxWidth}>
        <DialogTitle>{state.isNew ? `Agregar item en ${caption.toLowerCase()}` : `Editar item en ${caption.toLowerCase()}`} </DialogTitle>
        <DialogContent>
          <CustomProperties validationSchema={props.validationSchema && props.validationSchema.innerType} {...{data, name, item, fields, rootValues}} initialValues={state.values} onChange={handleChangeValues}/>
          <DialogContentText>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

}