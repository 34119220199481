import React, { useEffect, useState } from "react";
import { PrefabItemSelVertical, PrefabItemSelHorizontal, GreenLedOn, GreenLedOff } from "./";

export const PrefabSeleccionMultipleOpciones = ({list, style = {}, defaultValue = [], lighting = true, type = "vertical"}) => {

  const [value, setValue] = useState(() => defaultValue);
  
  const handleOptionClick = (index) => {
    setValue(value.map((x, i) => i===index ? {...x, checked: !x.checked} : x));
  }

  useEffect(()=>{
    list && setValue(list.slice(0, 6).map((x, i) => ({ value: x.valor, checked: (i < 2)}) ));
  }, [list])

  const ItemComponent = type === "vertical" ? PrefabItemSelVertical : PrefabItemSelHorizontal;
  const direction = type === "vertical" ? "column" : "row";
  const led = type === "vertical";

  return (
    <div style={{fontSize: 10, padding: 1, whiteSpace: "nowrap", display: "flex", alignItems: "stretch", flexDirection: direction, ...style}}>
      {value.map((x, index) => 
        <ItemComponent key={index} {...x} lighting={lighting} onClick={()=>handleOptionClick(index)} led={led} ledOn={led && <GreenLedOn/>} ledOff={led && <GreenLedOff/>} />
      )}
    </div>
  )
}

