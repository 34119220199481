import React from "react";
import { Route } from "react-router-dom";
import { Logout } from "./controllers/account/Logout";
import { useAuthContext } from "./contexts/Auth";
import { RedirectToHome } from "./contexts/FeaturesAccess";
import { Layout } from "../_metronic/layout";
import { useEffect } from "react";
import { toAbsoluteRouterUrl } from "../_metronic/_helpers";
import { connect } from "react-redux";
import { Abm } from "./controllers/abm/Index";

const _PrivateRoutes = ({config}) => {
  const { reLogin } = useAuthContext();

  //se renueva el token cada 8 horas
  useEffect(()=>{
    const intervalId = setInterval(()=>{ reLogin() }, 1000 * 60 * 60 * 8);
    return ()=>{clearInterval(intervalId)}   
  }, [])

  return (
    <Layout>
      <Route path={toAbsoluteRouterUrl("logout")} component={Logout} />
      {config.controllers.filter(x => !x.esConsola).map(x => 
        <Route path={[
          toAbsoluteRouterUrl(`Abm/${x.name}/:action/:id`),
          toAbsoluteRouterUrl(`Abm/${x.name}/:action`),
          toAbsoluteRouterUrl(`Abm/${x.name}`)
        ]}  key={x.name}>
          <AbmComponent {...x}/>
        </Route>
      )}


      {config.controllers.filter(x => x.esConsola).groupBy(x =>({name: x.name}), (x, g) => ({...x, g})).map(x => {
        return x.g.map((item, index) => {
          return (
            <Route path={[
              toAbsoluteRouterUrl(`${item.name}/${index}/:action/:id`),
              toAbsoluteRouterUrl(`${item.name}/${index}/:action/`),
              toAbsoluteRouterUrl(`${item.name}/${index}/`),
            ]}>
              <AbmComponent {...item}/>
            </Route>
          )

        });

      }
      )}
      <Route path="*" component={RedirectToHome}/> 
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
  };
}

export const PrivateRoutes = connect(mapStateToProps)(_PrivateRoutes);


const AbmComponent = (props) => {
  const Comp = Abm({controller: props.name, action: props.action});
  return (
    <Comp />
  );
}
