import React from "react";
import { PrefabBasicFull, getBooleanValue } from "./";

export const prefabTextoCompleto = ({ style, item, onClick }) =>{

  return (
    <div style={style}>
      <PrefabBasicFull
        onClick={onClick}
        title={item ? (item.identificacion && item.identificacion.descripcion) : "Texto debajo"}
        hasHistory={item ? (item.propiedades && getBooleanValue(item.propiedades.HistorialHabilitado)) : true}
        hasInfo={item ? (item.propiedades && item.propiedades.TextoAyuda) : "Texto de ayuda"}
        hasMic={true}
        content={
          <div style={{width: "100%", backgroundColor: "#efeeec", borderRadius: 3, color: "#9e9a94", fontSize: 10, fontStyle: "italic", overflow: "hidden", padding: 1, whiteSpace: "nowrap", marginTop: 4, marginBottom: 4}}>
            Ingrese texto
          </div>
        }
      />
    </div>
  );
}