import React from "react";
import { PrefabBasic, BlueButton, getBooleanValue } from "./";

const ButtonIcon = ({style}) => {
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" style={style}><path d="M 5 9 C 2.199219 9 0 11.199219 0 14 L 0 36 C 0 38.800781 2.199219 41 5 41 L 32 41 C 34.800781 41 37 38.800781 37 36 L 37 14 C 37 11.199219 34.800781 9 32 9 Z M 50 12.3125 L 39 18.1875 L 39 31.8125 L 50 37.6875 Z"></path></svg>
}

export const prefabCapturarVideo = ({ style, item, onClick }) =>{
  return (
    <div style={style}>
      <PrefabBasic 
        onClick={onClick}
        title={item ? (item.identificacion && item.identificacion.descripcion) : "Video"}
        hasHistory={item ? (item.propiedades && getBooleanValue(item.propiedades.HistorialHabilitado)) : true}
        hasInfo={item ? (item.propiedades && item.propiedades.TextoAyuda) : "Texto de ayuda"}
        content={
          <BlueButton>
            <ButtonIcon style={{width: 16, height: 16, fill: "white"}}/>
          </BlueButton>
        }
      />
    </div>
  );
}