import React from "react";
import { PrefabBasicLarge, getBooleanValue, MicIcon, PrefabNumMinMaxVU } from "./";

export const prefabNumeroMinMaxVuCompacto = ({ style, item, onClick }) => {

  const min = item ? (item.propiedades && item.propiedades.ValorMinimo || 0) : 200;
  const max = item ? (item.propiedades && item.propiedades.ValorMaximo || 0) : 500;
  const value = item ? (Math.floor((max - min) / 2)) : 330;

  return (
    <div style={{ ...style, paddingTop: 4, paddingBottom: 4,}}>
      <PrefabBasicLarge
        onClick={onClick}
        title={item ? (item.identificacion && item.identificacion.descripcion) : "Num. min/max VU"}
        hasHistory={item ? (item.propiedades && getBooleanValue(item.propiedades.HistorialHabilitado)) : true}
        hasInfo={item ? (item.propiedades && item.propiedades.TextoAyuda) : "Texto de ayuda"}
        iconHTML={<MicIcon style={{fill: "#0c97c0", width: 14, height: 14}}/>}
        content={<PrefabNumMinMaxVU min={min} max={max} value={value} small={true}/> }
      />
    </div>
  );
  
}


