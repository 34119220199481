import React from 'react';
import { List, ListItem, Box, ListItemAvatar, Avatar, ListItemSecondaryAction} from '@material-ui/core';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';

const itemStyle = {marginTop: "8px", backgroundColor: "#2aaede05" }

export const SortableList = ({details, onReorderList, rows, columns, addItem, data}) => {

  const onSortEnd = ({oldIndex, newIndex}) => {
    onReorderList(arrayMove(rows, oldIndex, newIndex));
  };

  const handleBeforeSorting = (event) => {
    return details ? true : ["svg", "path"].some(x => x === event.target.tagName);
  }

  const SortableContainerComponent = SortableContainerFn({addItem, details});
  const SortableItemComponent = SortableItemFn({columns, data, details});

  return (
    <Box style={{ width: "100%"}}>
      <SortableContainerComponent shouldCancelStart={handleBeforeSorting} onSortEnd={onSortEnd}> 
        {rows && rows.map((item, index) => (
          <SortableItemComponent key={`item-${index}`} index={index} item={{...item, index}} />
        ))}
      </SortableContainerComponent>
    </Box>
  );
}


const SortableContainerFn = ({addItem, details}) => SortableContainer(({children}) => {
  return (
    <List> 
      {children}
      {addItem && !details && <ListItem style={{...itemStyle, border: "1px #2aaede dashed"}}>{addItem}</ListItem>}
    </List>
  );
});

const SortableItemFn = ({columns, data, details}) => SortableElement(({item}) => {
  const primaryText = columns
    .filter(x => x.field !== "acciones")
    .map(c => c.renderCell ? c.renderCell({row: item, extraData: data}) : ( item ? item[c.field] : "" ) )
    .filter(x => x !== ""); 
  return (
    <div key={item.index} style={{zIndex: 100000}}>
      <ListItem style={{...itemStyle, border: "1px #2aaede solid", backgroundColor: "white", opacity: 1}}>
        <ListItemAvatar>
          <Avatar style={{width: 30, height: 30}}>
            {item.index + 1}
          </Avatar>
        </ListItemAvatar>
        {primaryText.map((x, index) => index < (primaryText.length-1) ? <>{x}<span style={{width: 6}}>, </span></> : x)}
        {!details && 
          <ListItemSecondaryAction>
            {columns.some(x => x.field === "acciones") && columns.filter(x => x.field === "acciones")[0].renderCell({row: item})}
          </ListItemSecondaryAction>
        }
      </ListItem>
    </div>

  )
});