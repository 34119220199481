import React from "react";
import { PrefabBase, TitleComponent, InfoHTML, HistoryHTML, EmptyHTML } from "./";

export const PrefabBasicLarge =  React.memo(({ title, onClick=()=>{}, content, hasHistory = true, hasInfo, iconHTML = undefined }) => {

  const rows = [
    {
      items: [
        {content: <InfoHTML show={hasInfo}/>, px: 16},
        {content: title, component: TitleComponent, px: iconHTML ? 110 : 132},
        {content: iconHTML ? iconHTML : <EmptyHTML/> , px: iconHTML ? 28 : 6 },
        {content, px: 156},
        {content: <HistoryHTML show={hasHistory}/>, px: 28},
      ]    
    }
  ];

  return (
    <PrefabBase rows={rows} onClick={onClick} />
  );
})
