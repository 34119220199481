import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import createRootReducer from "./reducers";

/**
 * 
 * @param {} {auth, abms} lista de abms [{}: auth, {controller: string}]
 * @returns 
 */
export const useStore = ({auth, config}) => createStore(createRootReducer({auth, config}), applyMiddleware(thunk));

export default useStore;