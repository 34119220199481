import React from "react"
import { TimePicker as TPicker } from '@material-ui/lab';
import { TextField } from "@material-ui/core";

export const TimePicker = ({name, details = false, caption = "Fecha", formik, variant = "outlined", placeholder, onChange, minDate, invalidDateMessage, minDateMessage, maxDateMessage}) => {

  return (
    <div style={{ padding: 8 }}>

      <TPicker
        disabled={details}
        label={variant == "outlined" && caption}
        value={formik.values[name]}
        onChange={(value)=>{ 
          formik.setFieldValue(name, value)
        }}
        renderInput={(params) => <TextField  {...params} fullWidth  helperText=""  variant={variant} />}
      />
    </div>
  )
}

