import React from "react";
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

export const SortableItemDnd = (props) =>{
  const { id, children } = props;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    isDragging,
  } = useSortable({
      id,
    });
  
  const style = {
    transform: CSS.Translate.toString(transform),
    ...isDragging 
    ?{
      zIndex: "10000" , 
      position: "relative", 
      boxShadow: "1px 3px 1px #9E9E9EEE"
    }
    :{
      zIndex: "10", 
    }
  };

  const rootProps = {
    ref: setNodeRef,
    style,
    ...attributes,
  }

  return (
    <>
      {children({...props, listeners: {...listeners, style: {...listeners.style, cursor: "move"}}, rootProps, isDragging})}
    </>
  )
}