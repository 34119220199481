import React from "react"
import { FormControl, InputLabel, makeStyles, MenuItem, OutlinedInput, Select } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
  },
}));

export const SelectGiz = ({name, caption, formik, onChange, items,  placeholder = "Seleccione...", labelWidth = 80, children = null, multiple, renderValue }) => {
  
  const classes = useStyles();

  return (
    <div  style={{ margin: 8 }}>
      <FormControl fullWidth variant="outlined" className={classes.formControl} >
        <InputLabel>
          {caption}
        </InputLabel>
        <Select
          name={name}
          id={name}
          value={formik.values[name]}
          onChange={onChange ? onChange : (event, value) => {
            console.log(value);
            formik.setFieldValue(name, value.props.value);
          }}
          input={<OutlinedInput label={caption} />}
          error={formik.touched[name] && Boolean(formik.errors[name])}
          multiple={multiple}
          renderValue={renderValue}
        >
          {placeholder &&
            <MenuItem value="">
              <em>{placeholder}</em>
            </MenuItem>      
          }
          {items ? items.map(item => <MenuItem value={item.value}>{item.text}</MenuItem>) : children} 
        </Select>
        {formik.touched[name] && formik.errors[name] &&
          <p style={{color: "#f018a6", fontSize: "0.75rem", marginTop: "3px", marginRight: "14px", marginLeft: "14px"}} class="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id={`${name}-helper-text`}>{formik.errors[name]}</p>              
        }
      </FormControl>

    </div>
  )

}