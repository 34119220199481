import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  makeStyles, 
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionActions from '@material-ui/core/AccordionActions';

const useStylesAccordion = makeStyles((theme) => ({
}));

const useStylesAccordionSummary = makeStyles((theme) => ({
  disabled: {
    backgroundColor: "var(--celeste-quintuus) !important",
    color: "rgba(255, 255, 255, 1) !important",
    opacity: "0.6 !important",
  },
  expanded: {
    backgroundColor: "#1f5772 !important",
    color: "white !important",
    opacity: "1 !important",
  },
}));

export const SimpleAccordion = ({name, title, subTitle, activePanel, elevation=0, children, actions=[]}) => {
  
  const classes = {
    accordion: useStylesAccordion(),
    summary: useStylesAccordionSummary(),
  };

  return (
    <div style={{marginTop:"8px"}}>
    <Accordion square={false} expanded={activePanel === name} classes={classes.accordion} disabled={activePanel !== name} elevation={elevation}>
      <AccordionSummary 
        expandIcon={<ExpandMoreIcon />} classes={classes.summary}
      >
        <Typography sx={{ width: '33%', flexShrink: 0 }}>{title}</Typography>
        <Typography sx={{ color: 'text.secondary' }}>{subTitle}</Typography>
      </AccordionSummary>
      <AccordionDetails style={{marginTop: "16px"}}>
        {children}
      </AccordionDetails>
      {actions && 
        <>
          <Divider />
          <AccordionActions>
            {actions.map(x => x)}
          </AccordionActions>
        </>
      }
    </Accordion>
    </div>
    
  )

}
