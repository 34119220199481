import React, { useContext } from "react";

export const useForm = (props) => {
  return  { ...props };
}

export const FormContext = React.createContext(useForm); 

export const useFormContext = () => { 
  return useContext(FormContext); 
};

export const FormProvider = (props) => {
  const form = useForm(props);
  
  return (
    <FormContext.Provider value={form}>
      {props.children}
    </FormContext.Provider>
  );
};


