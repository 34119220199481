import React from "react";
import { PrefabBasicFull, getBooleanValue, PrefabNumMinMax } from "./";

export const prefabNumeroMinMaxCompleto = ({ style, item, onClick }) =>{

  const min = item ? (item.propiedades && item.propiedades.ValorMinimo || 0) : 200;
  const max = item ? (item.propiedades && item.propiedades.ValorMaximo || 0) : 500;
  const value = item ? (Math.floor((max - min) / 2)) : 330;

  return (
    <div style={style}>
      <PrefabBasicFull
        onClick={onClick}
        title={item ? (item.identificacion && item.identificacion.descripcion) : "Numérico Min/Max debajo"}
        hasHistory={item ? (item.propiedades && getBooleanValue(item.propiedades.HistorialHabilitado)) : true}
        hasInfo={item ? (item.propiedades && item.propiedades.TextoAyuda) : "Texto de ayuda"}
        hasMic={true}
        content={<PrefabNumMinMax min={min} max={max} value={value} style={{marginTop: 4, marginBottom: 4}}/>}
      />
    </div>
  );
}