import React, { useState, useMemo } from "react";
import { PrefabBasicFull, PrefabLayoutUI, calcHeight, PdfSvg } from "./";
//import { toAbsoluteUrl } from "../../../_metronic/_helpers/AssetsHelpers"
import { Document, Page, pdfjs } from "react-pdf";

//pdfjs.GlobalWorkerOptions.workerSrc = toAbsoluteUrl(`js/pdf.worker.js`);

export const prefabPdf = ({ style, item, onClick }) =>{

  const height = item && ((item.propiedades && item.propiedades.Alto) ? calcHeight(item.propiedades.Alto) : 25) || "unset";
  


  return (
    <div style={style}>
      <PrefabBasicFull
        onClick={onClick}
        title={item ? (item.identificacion && item.identificacion.descripcion) : "Visualización de PDF"}
        hasHistory={false}
        content={
          <PrefabLayoutUI 
            style={{height: height, padding: 4}}
            list={item && (item.propiedades ? [
              <PdfDocument urlData={item.propiedades.ArchivoPDF}/>
            ] : [])}
            defaultValue = {[
              <div style={{ flexDirection: "column", display: "flex", alignItems: "center" }}>
                {<PdfSvg style={{fill: "white", textAlign: "center", marginLeft: 140, marginRight: 140 }}/>}
              </div>
            ]}
          />
        }
      />
    </div>
  );
}


const PdfDocument = ({urlData}) => {

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const dataFile = useMemo(()=>urlData && dataURItoBlob(urlData), [urlData]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }

  return (
    <div>
      <Document
        renderMode="svg"
        file={dataFile}
        onLoadSuccess={onDocumentLoadSuccess}
        className="pdfViewer"
      >
        <Page pageNumber={pageNumber} width={330} />
      </Document>
      <p><span style={{color: "white"}}>Página {pageNumber} de {numPages}</span></p>
    </div>
  )
}

const dataURItoBlob = (dataURI) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], {type: mimeString});
  return blob;

}