import React, { useEffect, useState } from "react"
import Select from 'react-select'
import { MultiSelectGizStyles } from "./";

export const SelectOrigenDeDatosGiz = (props) => {

  const {formik, name, optionsList} = props;

  console.log(props);

  const [state, setState] = useState(()=>({
    loaded: false,
    tabla: formik.values[name],
  }));

  useEffect(()=>{
    setState(state => ({...state, loaded: true}));
  }, [])

  const placeholder = "Seleccione una tabla...";

  const handleClienteChange = (item) => {
    if(state.loaded){
      setState({
        ...state, 
        tabla: item.value
      })
      updateFormik(item.value)
    }
  }

  const updateFormik = (value) => {
    formik.setFieldValue(name, value);
  }

  return (
    <>
      <div  style={{ margin: 8, backgroundColor: "#f4f4f4", padding: 8 }}>
        <Select
          isDisabled={false}
          isMulti={false}
          name={name}
          options={optionsList}
          value={state.tabla && optionsList && optionsList.find(x => x.value === state.tabla) }
          onChange={value => handleClienteChange(value)}
          placeholder={placeholder}
          menuPortalTarget={document.body}
          menuPosition="fixed"
          styles={MultiSelectGizStyles.standard()}
        />
      </div>

    </>
  )
}