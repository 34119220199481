import React, { useEffect, useState } from "react"
import { FormControl, FormControlLabel, makeStyles, Switch } from "@material-ui/core"
import { DetailWrapperGiz } from "../";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const getFormikValue = (formik, name, defaultValue, props) => {
  const value = formik.values[name] !== undefined ? formik.values[name] : (defaultValue && eval(defaultValue)());
  return value && (value === true || value === "true" || value === "True");
}

export const SwitchGiz = React.memo((props) => {
  const {defaultValue, details = false, name, caption, formik, initialState = null, title, readOnly = true, onChange, variant = "outlined"} = props;
  const [timer, setTimer] = useState(null);
  const [value, setValue] = useState(()=>getFormikValue(formik, name, defaultValue));
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    console.log ({name, formikvalue: formik.values[name], initialState, props})
    setLoaded(true);
    return () => clearTimeout(timer);
  }, [])
  
  useEffect(()=>{
    timer !== null && clearTimeout(timer);
    setTimer( 
      formik ? setTimeout(actualizarFormik, 50) : onChange({target:{name, value: value}})
    );
  }, [value])

  const actualizarFormik = () => {
    formik.values[name] !== value && formik.setFieldValue(name, value);
  }

  const handleInputChange = () => {
    setValue(!value)
  }
  
  const classes = useStyles();

  if(details){
    return (
      <>
        <DetailWrapperGiz caption={caption}>{formik.values[name] ? "Sí" : "No"}</DetailWrapperGiz>
      </>
    )
  }

  return (
    <FormControl fullWidth  variant="outlined" className={classes.formControl} style={{margin:"4px 8px 4px 8px"}}>
      <FormControlLabel 
        control={
          <Switch
            title={title}
            variant="outlined"
            id={name}
            name={name}
            color="primary" 
            readOnly={readOnly}
            checked={value} 
            onChange={handleInputChange}
            value={true}/>
        }
        label={variant === "outlined" && caption}
        labelPlacement="end"
      />
    </FormControl>
  )
})
