/**
 * 
 * @param {*} controller string | nombre del controller en la api
 * @returns un objeto con constantes para operaciones asincronicas
 */
export const constants = (controller) => ({
  INDEX: {
    REQUEST: `${controller}_INDEX_REQUEST`,
    SUCCESS: `${controller}_INDEX_SUCCESS`,
    FAILURE: `${controller}_INDEX_FAILURE`,
  },
  GET_ITEM: {
    REQUEST: `${controller}_GET_ITEM_REQUEST`,
    SUCCESS: `${controller}_GET_ITEM_SUCCESS`,
    FAILURE: `${controller}_GET_ITEM_FAILURE`,
  },
  ADD_ITEM: {
    REQUEST: `${controller}_ADD_ITEM_REQUEST`,
    SUCCESS: `${controller}_ADD_ITEM_SUCCESS`,
    FAILURE: `${controller}_ADD_ITEM_FAILURE`,
  },
  EDIT_ITEM: {
    REQUEST: `${controller}_EDIT_ITEM_REQUEST`,
    SUCCESS: `${controller}_EDIT_ITEM_SUCCESS`,
    FAILURE: `${controller}_EDIT_ITEM_FAILURE`,
  },
  ADD_FORM: {
    REQUEST: `${controller}_ADD_FORM_REQUEST`,
    SUCCESS: `${controller}_ADD_FORM_SUCCESS`,
    FAILURE: `${controller}_ADD_FORM_FAILURE`,
  },
  EDIT_FORM: {
    REQUEST: `${controller}_EDIT_FORM_REQUEST`,
    SUCCESS: `${controller}_EDIT_FORM_SUCCESS`,
    FAILURE: `${controller}_EDIT_FORM_FAILURE`,
  },
  REMOVE_ITEM: {
    REQUEST: `${controller}_REMOVE_ITEM_REQUEST`,
    SUCCESS: `${controller}_REMOVE_ITEM_SUCCESS`,
    FAILURE: `${controller}_REMOVE_ITEM_FAILURE`,
  },
  GET_TABLE: {
    REQUEST: `${controller}_GET_TABLE_REQUEST`,
    SUCCESS: `${controller}_GET_TABLE_SUCCESS`,
    FAILURE: `${controller}_GET_TABLE_FAILURE`,
  },
  GET_TIMELINE: {
    REQUEST: `${controller}_GET_TIMELINE_REQUEST`,
    SUCCESS: `${controller}_GET_TIMELINE_SUCCESS`,
    FAILURE: `${controller}_GET_TIMELINE_FAILURE`,
  },
  LOADED_PROCESSED: `${controller}_LOADED_PROCESSED`,
});
