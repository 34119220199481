import React, { useEffect, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, InputAdornment, InputLabel, makeStyles, OutlinedInput, Avatar, InputBase } from "@material-ui/core"
import { Palette } from "@material-ui/icons";
import { ChromePicker } from "react-color";

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
  },
  divColor: {
    minWidth: 120,
    width: "100%",
    maxWidth:"50px",
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    borderRadius: "4px",
    
  },
  icon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    maxWidth: theme.spacing(3),
    maxHeight: theme.spacing(3),
  },

  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    maxWidth: theme.spacing(4),
    maxHeight: theme.spacing(4),
    cursor: "pointer",
  },

}));


export const ColorGiz = ({details = false, size, name, caption, formik, initialState = null, title, readOnly = true, onChange, variant = "outlined"}) => {

  const [open, setOpen] = useState(false);
  const [timer, setTimer] = useState(null);
  const [value, setValue] = useState(()=>formik ? (formik.values[name] || "") : (initialState || ""));
  const [color, setColor] = useState(()=>formik ? (formik.values[name] || "") : (initialState || ""));
  const [loaded, setLoaded] = useState(false);
  const error = formik && formik.touched[name] && formik.errors[name];
  const hasError = Boolean(error);

  useEffect(()=>{
    setLoaded(true);
  }, [])

  useEffect(()=>{
    if(loaded){
      timer !== null && clearTimeout(timer);
      setTimer( 
        formik ? setTimeout(actualizarFormik, 100) : onChange({target:{name, value: value}})
      );  
    }
    return ()=>{
      clearTimeout(timer);
    }
  }, [value])

  const actualizarFormik = () => {
    value !== formik.values[name] && formik.setFieldValue(name, value);
  }

  const handleInputChange = () => {
    setValue(color)
    handleClose();
  }
  
  const handleColorChange = (color) => {
    setColor(color.hex.toUpperCase());
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleOpen = () => {
    !details && setOpen(true);
  }

  const classes = useStyles();

  const InputColor = variant === "standard" ? InputBase : OutlinedInput;

  return (

    <div style={{ margin: 8 }}>
      <FormControl fullWidth variant={variant} className={classes.formControl}  onClick={handleOpen} error={hasError}>
        {variant === "outlined" && 
          <InputLabel  htmlFor={`${name}-${variant}-color`} shrink={value} error={hasError}>
            {caption}
          </InputLabel>
        }
        <InputColor 
          size={size}
          readOnly={true}
          id={`${name}-${variant}-color`}
          type="text" 
          endAdornment={
            <InputAdornment position="end">
              <Avatar className={classes.avatar} style={{backgroundColor: value, width:20, height:20, padding: 0}} >
                <Palette style={{width:18, height:18}} className={classes.icon}/>
              </Avatar>
            </InputAdornment>
          }
          value={value} 
          label={variant === "outlined" ? caption : ""}
          style={
            variant === "standard" ? {        
              border: "0px solid white",
              borderBottom: "0px solid #949494",
              borderRadius: "0px"
            } : {}
          }
        />
        {hasError &&
          <FormHelperText>{error}</FormHelperText>
        }
            {/*<div onClick={handleOpen} className={classes.divColor}><div style={{backgroundColor: value, color: "transparent", width:"100%",  height: "25px",}}>.</div></div>*/}
      </FormControl>
      <Dialog open={open}  aria-labelledby="form-dialog-title" onClose={handleClose}>
        <DialogTitle id="form-dialog-title">Selección de color</DialogTitle>
        <DialogContent>
          <ChromePicker disableAlpha={true} color={color} onChange={handleColorChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleInputChange}>Aceptar</Button>
        </DialogActions>
      </Dialog>
    </div>


  )
}
