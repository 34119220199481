import { Button, CardContent, CardHeader, Grid } from '@material-ui/core';
import React, {useState, useEffect} from 'react';
import { useHistory, useParams } from 'react-router';
import { Field } from '../../helpers';

const Details = (props) => {
  const { config, data, item, getTimeline } = props;
  let history = useHistory();

  const handleBackClick = (event) => {
    event.preventDefault();
    history.goBack();
  }

  const { loaded, loadedProcessed } = props;
  let { id } = useParams();
  const [internalLoaded, setInternalLoaded] = useState(false);

  useEffect(() => {
    getTimeline(id)
      .then(response => {
      })
      .catch(error=> {
      });
  }, [])

  useEffect(() => {
    if(loaded){
      loadedProcessed()
      setInternalLoaded(true); 
    }
  }, [loaded])


  return (
    <>      
      <CardHeader
        action={
          <Button onClick={handleBackClick}>
            Volver
          </Button>
        }
        title={config.timeline.caption}
        subheader="Timeline"
      />
      {internalLoaded &&
        <CardContent>
          <Grid container spacing={2}>
            {
              config.timeline && <Field details={true} {...{formik: null, data, item}} field={config.timeline} />
            }
          </Grid>
        </CardContent>
      }
    </>
  );
};

export default Details;