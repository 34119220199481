import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useAuthContext } from '../contexts/Auth.jsx';
import { LoginPath } from './account/Login.jsx';

export const TokenPath = "/Token/:paramToken";

export const Token = () => {

  const {auth, onAuth} = useAuthContext();

  let paramToken = useParams().paramToken;

  if(paramToken){
    onAuth(paramToken, true);
  }

  return (
    <>
      {!auth.isAuthenticated && auth.intentParamToken && 
        <Redirect to={LoginPath}/>
      }
    </>
  )

}