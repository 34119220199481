import { FormHelperText, IconButton, InputAdornment, InputBase, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { Highlight, highlightTest } from './Highlight';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SearchSharpIcon from '@material-ui/icons/SearchSharp';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const getOptions = (optionsList, data, options, renderOp, props) => {
  return new Promise((resolve, reject) => { 
    if(optionsList) {
      resolve(optionsList);
      return;
    }
    if(data[options]){
      resolve(renderOp ? data[options].map((option) => renderOp({...option, ...props})): data[options]);
      return;
    }
    data.getTable(options).then(response => 
      resolve(response)
    ).catch(error =>
      reject(error)
    )    
  });
}

const getFormikValue = (items, formik, name) => {
  var value = formik && formik.values[name];
  if(value){
    if(Array.isArray(value)){
      return value.map(x => items.find(item => item.value === x) || x)
    }else{
      return items.filter(x => x.value === value);
    }
  }
}

export const MultiSelectListGiz = (props) =>  {
  
  const {details = false, isMulti = true, name, options, optionsList, formik, data, renderOption, variant="outlined" } = props;
  const error = formik &&  formik.errors[name];
  const hasError = Boolean(error);
  const [state, setState] = useState({items: null, value: null, controlStyle: {},  touched: false})

  useEffect(() => {
    let v = null;
    if(state.touched){
      if(state && state.value){
        if(isMulti){
          v = state.value.map(x => x.value);
        }else{
          v = Array.isArray(state.value) ? state.value.length > 0  && state.value[0].value : state.value.value;
        }
        v !== formik.values[name] && formik.setFieldValue(name, v)
      }else{
        formik.setFieldValue(name, "")
      }  
    }
  }, [state.value])

  const handleChange = (value) => {
    setState({
      ...state,
      value,
      touched: true,
    })
  }


  useEffect(() => {
    const renderOp = renderOption ? eval(renderOption) : x => ({label: x.label, value: x.value, isDisabled: x.isDisabled, selected: x.selectedFn ? (x.selectedFn && eval(x.selectedFn)(x)) : x.selected });
    getOptions(optionsList, data, options, renderOp, props).then(
      items => {

        const value = (items && (items.some(x => x.selected))) 
        ? items.filter(x => x.selected) 
        : getFormikValue(items, formik, name) ;

        console.log({items, value})

        setState({
          items: items.map(item => ({...item, selected: value && value.some(v => v.value === item.value)})),
          value,
        });
      }
    ).catch(error=>
      console.log(error)
    );
  }, [])

  const wrapperStyle = variant === "standard" 
    ? { padding: 8 }
    : { margin: 8 };

  return (
    <div  style={wrapperStyle}>
      {state.items &&
        <CheckboxList
          isDisabled={details}
          isMulti={isMulti}
          options={state.items}
          //value={state.value}
          onChange={handleChange}
        />

      }

      {hasError &&
        <FormHelperText>{error}</FormHelperText>
      }
    </div>
  )
}

const useStylesCh = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const CheckboxList = ({isMulti, options, onChange}) => {

  const classes = useStylesCh();
  const [state, setState] = useState({options: options && options.map((item, index) => ({...item, index}))});
  
  const handleFilterChange = (event) => {
    setState({...state, filter: event.target.value});
  }

  const handleFilterDelete = () => {
    setState({...state, filter: ""});
  }

  const handleToggle = (index) => {
    console.log({index})
    const newOptions = isMulti 
      ? ( state.options.map((x, i) => i === index ? {...x, selected: !x.selected} : x ) )
      : ( state.options.map((x, i) => i === index ? {...x, selected: !x.selected} : {...x, selected: false} ) );
    const newValue = newOptions.filter(x => x.selected)
    setState({...state, options: newOptions});
    onChange && onChange(newValue);
  };

  const search = state.filter && highlightTest(state.filter);

  const optionsList = useMemo(()=> 
    state.options 
      ? (
        search
          ? state.options.filter(item => search.test(item.label))
          : state.options
      )
      : []  
  , [state.options, search]);
  
  return (
    <>
      <InputBase 
        value={state.filter} 
        onChange={handleFilterChange} 
        style={{padding:8, width: "100%"}}
        endAdornment={ state.filter && 
          <InputAdornment>
            <IconButton onClick={handleFilterDelete} style={{padding: 8}}>
              <HighlightOffIcon/>
            </IconButton>
          </InputAdornment>  
        }
        inputProps={{style: {marginLeft: 8}}}
        startAdornment={
          <InputAdornment>
            <SearchSharpIcon />
          </InputAdornment>  
        }
      />
      <List className={classes.root} style={{maxHeight: 350, overflowY: "auto" }}>
        {state.options && state.options.length === 0 &&
          <ListItem role={undefined} dense>              
            <ListItemText primary="No hay items para mostrar" />
          </ListItem>
        }
        {state.filter && optionsList.length === 0 &&
          <ListItem role={undefined} dense>              
            <ListItemText primary="La búsqueda no arrojó resultados" />
          </ListItem>
        }

        {optionsList.map((item) => {
          const labelId = `checkbox-list-label-${item.index}`;
          return (
            <ListItem key={item.index} role={undefined} dense button onClick={()=>handleToggle(item.index)}>
              {!item.isDisabled &&
                <ListItemIcon>
                  {item.selected && 
                    (isMulti ? <CheckCircleOutlineIcon /> : <RadioButtonCheckedIcon/>)
                  }
                  {!item.selected && <RadioButtonUncheckedIcon/>}
                </ListItemIcon>
              }
              <ListItemText id={labelId} primary={<Highlight text={item.label} search={state.filter}/>} />
            </ListItem>
          );
        })}
      </List>
    </>
  );
}