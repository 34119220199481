import React from "react";
import { PrefabBasicFull, PrefabLayoutUI, calcHeight, calcFontSize } from "./";

const getValue = (item) => {

  return {
    height: item && ((item.propiedades && item.propiedades.Alto) ? calcHeight(item.propiedades.Alto) : 30) || 30,
    izq:{
      fontWeight: 500,
      fontSize: item && ((item.propiedades && item.propiedades.MedidaFuenteIzquierda) ? calcFontSize(item.propiedades.MedidaFuenteIzquierda) : 10) || 10,
      color: item && ((item.propiedades && item.propiedades.ColorFrenteIzquierda) ? item.propiedades.ColorFrenteIzquierda : "white") || "white",
      valor: item && ((item.propiedades && item.propiedades.ValorIzquierda) ? item.propiedades.ValorIzquierda : "ETIQUETA") || "ETIQUETA",
      textTransform: item && (
        (item.propiedades && item.propiedades.MayusculasIzquierda) 
        ? "uppercase"
        : ((item.propiedades && item.propiedades.MayusculasIzquierda) 
          ? "lowercase"
          : "unset")
      ) || "unset",
      alignItems: item && 
      (
        (item.propiedades && item.propiedades.AlineacionVerticalIzquierda === "Arriba") 
        ? "flex-start"
        : ((item.propiedades && item.propiedades.AlineacionVerticalIzquierda === "Abajo") 
          ? "flex-end"
          : "center")
      ) || "center",
      textAlign: item && 
      (
        (item.propiedades && item.propiedades.AlineacionVerticalIzquierda === "Izquierda") 
        ? "left"
        : ((item.propiedades && item.propiedades.AlineacionVerticalIzquierda === "Derecha") 
          ? "right"
          : "center")
      ) || "center",      
    },
    der: {
      fontWeight: 500,
      fontSize: item && ((item.propiedades && item.propiedades.MedidaFuenteDerecha) ? calcFontSize(item.propiedades.MedidaFuenteDerecha) : 12) || 12,
      color: item && ((item.propiedades && item.propiedades.ColorFrenteDerecha) ? item.propiedades.ColorFrenteDerecha : "white") || "white",
      valor: item && ((item.propiedades && item.propiedades.ValorDerecha) ? item.propiedades.ValorDerecha : "ETIQUETA") || "ETIQUETA",
      textTransform: item && (
        (item.propiedades && item.propiedades.MayusculasDerecha) 
        ? "uppercase"
        : ((item.propiedades && item.propiedades.MayusculasDerecha) 
          ? "lowercase"
          : "unset")
      ) || "unset",
      alignItems: item && 
      (
        (item.propiedades && item.propiedades.AlineacionVerticalDerecha === "Arriba") 
        ? "flex-start"
        : ((item.propiedades && item.propiedades.AlineacionVerticalDerecha === "Abajo") 
          ? "flex-end"
          : "center")
      ) || "unset",
      textAlign: item && 
      (
        (item.propiedades && item.propiedades.AlineacionVerticalDerecha === "Izquierda") 
        ? "left"
        : ((item.propiedades && item.propiedades.AlineacionVerticalDerecha === "Derecha") 
          ? "right"
          : "center")
      ) || "center",

    }
  }

}

export const prefabTextoTextoUI = ({ style, item, onClick }) =>{

  const value = getValue(item);

  return (
    <div style={style}>
      <PrefabBasicFull
        onClick={onClick}
        title={item ? (item.identificacion && item.identificacion.descripcion) : "Doble etiqueta al 50%"}
        hasHistory={false}
        content={
          <PrefabLayoutUI 
            style={{height: value.height, padding: 4}}
            list={item && (item.propiedades ? [
              <div style={{flex: 1, display: "flex", flexDirection: "row", ...value.izq}}>
                <div style={{ textAlign: "inherit", width: "100%"}}>{value.izq.valor}</div>
              </div>,
              <div style={{flex: 1, display: "flex", flexDirection: "row", ...value.der}}>
                <div style={{ textAlign: "inherit", width: "100%"}}>{value.der.valor}</div>
              </div>,
            
            ] : [])}
            defaultValue = {[
              <div style={{flex: 1, display: "flex", flexDirection: "row", ...value.izq}}>
                <div style={{ textAlign: "inherit", width: "100%"}}>{value.izq.valor}</div>
              </div>,
              <div style={{flex: 1, display: "flex", flexDirection: "row", ...value.der}}>
                <div style={{ textAlign: "inherit", width: "100%"}}>{value.der.valor}</div>
              </div>,
            ]}
          />
        }
      />
    </div>
  );
}