/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "../app/Routes";
import { I18nProvider } from "../_metronic/i18n";
import { MaterialThemeProvider } from "../_metronic/layout";
import { PageTitleProvider } from "./contexts/PageTitle";
import { AuthProvider } from "./contexts/Auth";
import { SnackProvider } from "./contexts/Snack";
import 'moment/locale/es-mx';
import moment from "moment";
import { FeatureAccessProvider } from "./contexts/FeaturesAccess";
import AdapterMoment from '@material-ui/lab/AdapterMoment';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import './helpers/extensions/string.extensions.js';
import './helpers/extensions/array.extensions.js';


import { pdfjs } from "react-pdf";
import { toAbsoluteUrl } from "../_metronic/_helpers";
moment.locale("es-mx");

//const pdfjs = await import('pdfjs-dist/build/pdf');
//const pdfjsWorker = import('./pdf.worker.js');

//pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
pdfjs.GlobalWorkerOptions.workerSrc = toAbsoluteUrl(`js/pdf.worker.js`);
//pdfjs.GlobalWorkerOptions.

export default function App({ basename }) {
  
  return (
    <BrowserRouter basename={basename}>
      {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
      <MaterialThemeProvider>
        {/* Provide `react-intl` context synchronized with Redux state.  */}
        <I18nProvider>
          {/* Render routes with provided `Layout`. */}
          <PageTitleProvider>
            <AuthProvider>
              <SnackProvider>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <FeatureAccessProvider>
                    <Routes />
                  </FeatureAccessProvider>
                </LocalizationProvider>
              </SnackProvider>

            </AuthProvider>
          </PageTitleProvider>
        </I18nProvider>
      </MaterialThemeProvider>
    </BrowserRouter>
  );
}
