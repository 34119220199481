import React from "react";
import { PrefabBase, TitleComponent, HistoryHTML, InfoHTML } from "./";

export const PrefabBasic = React.memo(({ title, onClick=()=>{}, content, hasHistory = true, hasInfo = "" }) => {
  const rows = [
    {
      items: [
        {content: <InfoHTML show={hasInfo}/>, px: 16},
        {content: title, component: TitleComponent, px: 238 },
        {content, px: 56},
        {content: <HistoryHTML show={hasHistory}/>, px: 28},
      ]    
    }
  ];

  return (
    <PrefabBase rows={rows} onClick={onClick} />
  );
})
