import React from "react";
import { PrefabBasicFull, getBooleanValue, PrefabSeleccionMultipleOpciones } from "./";

export const prefabSeleccionVerticalMultipleOpciones = ({ style, item, onClick }) =>{
  return (
    <div style={style}>
      <PrefabBasicFull
        onClick={onClick}
        title={item ? (item.identificacion && item.identificacion.descripcion) : "Selección vertical múltiple de opciones"}
        hasHistory={item ? (item.propiedades && getBooleanValue(item.propiedades.HistorialHabilitado)) : true}
        hasInfo={item ? (item.propiedades && item.propiedades.TextoAyuda) : "Texto de ayuda"}
        hasMic={false}
        content={
          <PrefabSeleccionMultipleOpciones 
            list={item && (item.propiedades.ListaOpciones ? item.propiedades.ListaOpciones : [])} 
            style={{marginTop: 4, marginBottom: 4}}
            defaultValue={[
              {checked: true, value: "Opción 1"}, 
              {checked: true, value: "Opción 2"}, 
              {checked: false, value: "Opción 3"}, 
              {checked: false, value: "Etc."}, 
            ]}
          />
        }
      />
    </div>
  );
}
