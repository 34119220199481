import React, { useEffect, useState } from "react"
import { CardContent, Button, Grid, ImageListItemBar, ImageList, ImageListItem, IconButton } from "@material-ui/core"
import { Cancel } from "@material-ui/icons";

export const ImageGiz = ({name, details = false, caption, formik, placeholder, type, autoFocus, disabled, style = {}, inputProps = {}, multiline = false, rows = undefined, variant="outlined", size="medium"}) => {

  //const error = formik && formik.errors[name];
  //const hasError = Boolean(error);
  const [selectedFile, setSelectedFile] = useState(null)
  const [imageData, setImageData] = useState(() => formik && formik.values[name])

  useEffect(()=>{
    formik && formik.values[name] !== imageData && formik.setFieldValue(name, imageData);
  },[imageData])

  const handleFileCancel = (event) => {
    setSelectedFile(null);
    setImageData(null);
  }
  
    // On file select (from the pop up) 
  const onFileChange = (event) => { 

    // Update the state 
    if(event.target.files.length > 0) {

      var reader  = new FileReader();
      reader.onloadend = function () {
        setImageData(reader.result)
      }
      reader.readAsDataURL(event.target.files[0]);
      setSelectedFile( event.target.files[0] ); 
      event.target.value = [ ];

    }
    
  }; 
  

  return (
    <div style={{ margin: 8 }}>

      {!selectedFile && !imageData &&
        <CardContent>
          <div> 
            <input 
              type="file" 
              name="imageUpload" 
              id={name}
              style={{display: "none"}} 
              onChange={onFileChange} 
            /> 
            <Button variant="contained" disabled={selectedFile !== null}><label for={name} >Seleccione el arhivo de imagen.</label></Button>
          </div> 
        </CardContent>
      }


      {imageData &&
        <Grid container> 
          <Grid item xs={12}>
            <ImageList cols={1}>
              <ImageListItem >
                <img
                  alt="imagen"
                  src={imageData}
                  style={{padding: 0, maxWidth: 340, display:"inline"}}
                  draggable={false}
                />  
                <ImageListItemBar title={!selectedFile ? "Imagen" : selectedFile.name} subtitle={!selectedFile ? "" : selectedFile.type} position="top" actionIcon={<IconButton onClick={handleFileCancel}><Cancel style={{fill: "#FFFFFF", opacity: 0.6}}/></IconButton>} ></ImageListItemBar>
              </ImageListItem>
            </ImageList>
          </Grid>
        </Grid> 
      }


    </div>
  )
}