import React from "react";
import { PrefabBasicFull, PrefabLayoutUI, calcHeight, PdfSvg } from "./";

export const prefabLineaDivisoria = ({ style, item, onClick }) =>{

  return (
    <div style={style}>
      <PrefabBasicFull
        onClick={onClick}
        title={item ? (item.identificacion && item.identificacion.descripcion) : "Línea divisora"}
        hasInfo={item ? (item.propiedades && item.propiedades.TextoAyuda) : "Texto de ayuda"}
        hasHistory={false}
        content={
          <hr style={{borderTop: "1px solid #e5e5e5", width: "100%"}}/>
        }
      />
    </div>
  );
}
