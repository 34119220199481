import React from "react";
import { CustomProperties } from "../";

export const CustomBasicProperties = (props) => {
  const { name, onChange, formik } = props;
  return (
    <CustomProperties
      {...props} 
      onChange={(values) => {
        const arrayValues = Object.keys(values).map(x => ({name: x, value: values[x]}));
        formik && formik.setFieldValue(name, arrayValues);
        onChange && onChange(arrayValues);
      }}
      formik={undefined}
      initialValues={formik.values[name]}
    />
  )
}