/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive, toAbsoluteRouterUrl} from "../../../../_helpers";
import { useFeatureAccessContext } from "../../../../../app/contexts/FeaturesAccess";

import * as icons from "@material-ui/icons";

const getIcon = name => {
  return icons[name];
}


export function AsideMenuCustomList({ layoutProps }) {

  const {menu} = useFeatureAccessContext(); 
  const location = useLocation();

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };

  return (
      <>
        <SystemMenu menu={menu} menuItemActive={getMenuItemActive} layoutProps={layoutProps} />        
      </>
  );
}

const SystemMenu = ({menu, layoutProps, menuItemActive}) =>{
  
  return (
    <ul className={`menu-nav ${layoutProps.ulClasses}`}>
      <>
        {menu.sectionRoot && <SystemSection {...menu.sectionRoot}  menuItemActive={menuItemActive}/>}
        {menu.sections && menu.sections.map((section, section_index) => (
          <SystemSection key={section_index} {...section}  menuItemActive={menuItemActive}/>
        ))}
      </>
    </ul>

  )

}

const SystemSection = ({caption, features, menuItemActive}) => {
  return (
    <>
      {caption && 
        <li className="menu-section">
          <h4 className="menu-text">{caption}</h4>
          <i className="menu-icon ki ki-bold-more-hor icon-md"></i>
        </li>
      }
      {features && features.map((feature,feature_index)=> (
        feature.features ? 
          <FeatureGroup key={feature_index} {...feature} menuItemActive={menuItemActive}/> :
          <Feature key={feature_index} {...feature} menuItemActive={menuItemActive} />
      ))}
    </>
  )
}


export const FeatureGroup  = ({caption, features, url, icon, menuItemActive}) => {
  const Icon = getIcon(icon);
  return(
    <>
      <li
          className={`menu-item menu-item-submenu ${menuItemActive(url, true)}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
      >
        <a className="menu-link menu-toggle">
          <span className="svg-icon menu-icon">
            {Icon 
            ? <Icon /> 
            : (icon ?
              (<SVG src={toAbsoluteUrl(icon)}/>) :
              <SVG src={toAbsoluteUrl("media/svg/icons/Custom/Scrubber.svg")}/>
            )}
          </span>
          <span className="menu-text">{caption}</span>
          <i className="menu-arrow"/>
        </a>
        <div className="menu-submenu ">
          <i className="menu-arrow"/>
          <ul className="menu-subnav">
          {features && features.map((feature, feature_index)=> (
            feature.features ? 
              <FeatureGroup key={feature_index} {...feature}  menuItemActive={menuItemActive}/> :
              <Feature key={feature_index} {...feature} menuItemActive={menuItemActive} />
          ))}
          </ul>
        </div>
      </li>
    </>
  )
}


const Feature = ({caption, url, icon, menuItemActive}) => {

  const Icon = getIcon(icon);

  return(
    <li
      className={`menu-item ${menuItemActive(url, false)}`}
      aria-haspopup="true"
    >

        <NavLink className="menu-link" to={toAbsoluteRouterUrl(url ? url : "/")}>
          <span className="svg-icon menu-icon">
          {Icon 
          ? <Icon /> 
          : (icon ?
            (<SVG src={toAbsoluteUrl(icon)}/>) :
            <SVG src={toAbsoluteUrl("media/svg/icons/Custom/Scrubber.svg")}/>
          )}
          </span>
          <span className="menu-text">{caption}</span>
        </NavLink>

    </li>
  )
}