import React from "react";
import { PrefabBasicLarge, getBooleanValue, PrefabPicture } from "./";

export const prefabPintarCompacto = ({ style, item, onClick }) => {
  return (
    <div style={style}>
      <PrefabBasicLarge
        onClick={onClick}
        title={item ? (item.identificacion && item.identificacion.descripcion) : "Slider al lado"}
        hasHistory={item ? (item.propiedades && getBooleanValue(item.propiedades.HistorialHabilitado)) : true}
        hasInfo={item ? (item.propiedades && item.propiedades.TextoAyuda) : "Texto de ayuda"}
        content={<PrefabPicture/>}
      />
    </div>
  );
}

