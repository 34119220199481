import React from "react";
import { PrefabBasic, BlueButton, getBooleanValue } from "./";

const MicIcon = ({style}) => {
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" style={style}><path d="M 25 0 C 18.921875 0 14 4.785156 14 10.71875 L 14 11 L 21.5 11 C 22.050781 11 22.5 11.449219 22.5 12 C 22.5 12.550781 22.050781 13 21.5 13 L 14 13 L 14 15 L 21.5 15 C 22.050781 15 22.5 15.449219 22.5 16 C 22.5 16.550781 22.050781 17 21.5 17 L 14 17 L 14 19 L 21.5 19 C 22.050781 19 22.5 19.449219 22.5 20 C 22.5 20.550781 22.050781 21 21.5 21 L 14 21 L 14 23.28125 C 14 29.214844 18.921875 34 25 34 C 31.078125 34 36 29.214844 36 23.28125 L 36 21 L 28.5 21 C 27.945313 21 27.5 20.550781 27.5 20 C 27.5 19.449219 27.945313 19 28.5 19 L 36 19 L 36 17 L 28.5 17 C 27.945313 17 27.5 16.550781 27.5 16 C 27.5 15.449219 27.945313 15 28.5 15 L 36 15 L 36 13 L 28.5 13 C 27.945313 13 27.5 12.550781 27.5 12 C 27.5 11.449219 27.945313 11 28.5 11 L 36 11 L 36 10.71875 C 36 4.785156 31.078125 0 25 0 Z M 9.8125 17.125 C 9.402344 17.210938 9.113281 17.582031 9.125 18 L 9.125 23 C 9.125 30.714844 14.6875 37.183594 22 38.59375 L 22 44 L 28 44 L 28 38.59375 C 35.3125 37.183594 40.875 30.714844 40.875 23 L 40.875 18 C 40.875 17.515625 40.484375 17.125 40 17.125 C 39.515625 17.125 39.125 17.515625 39.125 18 L 39.125 23 C 39.125 30.800781 32.800781 37.125 25 37.125 C 17.199219 37.125 10.875 30.800781 10.875 23 L 10.875 18 C 10.878906 17.75 10.773438 17.511719 10.589844 17.34375 C 10.402344 17.175781 10.15625 17.09375 9.90625 17.125 C 9.875 17.125 9.84375 17.125 9.8125 17.125 Z M 15.5 46 C 13.585938 46 12.03125 47.5625 12.03125 49.46875 L 12 50 L 37.875 49.9375 L 37.90625 49.46875 C 37.90625 47.5625 36.351563 46 34.4375 46 Z"></path></svg>
}

export const prefabCapturarNotaVoz = ({ style, item, onClick }) =>{
  return (
    <div style={style}>
      <PrefabBasic 
        onClick={onClick}
        title={item ? (item.identificacion && item.identificacion.descripcion) : "Nota de voz"}
        hasHistory={item ? (item.propiedades && getBooleanValue(item.propiedades.HistorialHabilitado)) : true}
        hasInfo={item ? (item.propiedades && item.propiedades.TextoAyuda) : "Texto de ayuda"}
        content={
          <BlueButton>
            <MicIcon style={{width: 16, height: 16, fill: "white"}}/>
          </BlueButton>
        }
      />
    </div>
  );
}