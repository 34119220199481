import React from "react";
import { PrefabBasicLarge, getBooleanValue } from "./";

const IconHTML = ({style}) => {
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" style={style}><path d="M25,2C12.317,2,2,12.317,2,25s10.317,23,23,23s23-10.317,23-23S37.683,2,25,2z M25,28c-0.462,0-0.895-0.113-1.286-0.3 l-6.007,6.007C17.512,33.902,17.256,34,17,34s-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414l6.007-6.007 C22.113,25.895,22,25.462,22,25c0-1.304,0.837-2.403,2-2.816V8c0-0.553,0.447-1,1-1s1,0.447,1,1v14.184c1.163,0.413,2,1.512,2,2.816 C28,26.657,26.657,28,25,28z"></path></svg>
}

export const prefabHora = ({ style, item, onClick }) =>{

  return (
    <div style={style}>
      <PrefabBasicLarge
        onClick={onClick}
        title={item ? (item.identificacion && item.identificacion.descripcion) : "Hora"}
        hasHistory={item ? (item.propiedades && getBooleanValue(item.propiedades.HistorialHabilitado)) : true}
        hasInfo={item ? (item.propiedades && item.propiedades.TextoAyuda) : "Texto de ayuda"}
        iconHTML={<div style={{textAlign: "center"}}><IconHTML style={{fill: "#0c97c0", width: 14, height: 14}}/></div>}
        content={
          <div style={{
              textAlign: "right", 
              backgroundColor: "#efeeec", 
              borderRadius: 3, 
              color: "#1b1b1a", 
              fontSize: 8, 
              overflow: "hidden", 
              padding: 4, 
              whiteSpace: "nowrap",
              fontWeight: 700,
              height: 18,
            }}
          >
            HH:MM
          </div>
        }
      />
    </div>
  );
}