import React from "react";
import { PrefabBasicFull, getBooleanValue, PrefabSlider } from "./";

export const prefabDeslizableCompleto = ({ style, item, onClick }) =>{

  return (
    <div style={style}>
      <PrefabBasicFull
        onClick={onClick}
        title={item ? (item.identificacion && item.identificacion.descripcion) : "Slider debajo"}
        hasHistory={item ? (item.propiedades && getBooleanValue(item.propiedades.HistorialHabilitado)) : true}
        hasInfo={item ? (item.propiedades && item.propiedades.TextoAyuda) : "Texto de ayuda"}
        hasMic={false}
        content={<PrefabSlider  style={{margin: 2}}/>}
      />
    </div>
  );
}