import React from "react";
import { PrefabBasicLarge, getBooleanValue } from "./";

export const prefabContrasenia = ({ style, item, onClick }) =>{
  return (
    <div style={style}>
      <PrefabBasicLarge
        onClick={onClick}
        title={item ? (item.identificacion && item.identificacion.descripcion) : "Password"}
        hasHistory={false}
        hasInfo={item ? (item.propiedades && item.propiedades.TextoAyuda) : "Texto de ayuda"}
        content={
          <div style={{width: "100%", backgroundColor: "#efeeec", borderRadius: 3, color: "#9e9a94", fontSize: 10, fontStyle: "italic", overflow: "hidden", padding: 1, whiteSpace: "nowrap"}}>
            Ingrese valor oculto
          </div>
        }
      />
    </div>
  );
}