import { FormControl, FormHelperText, InputLabel, makeStyles } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
  },
}));

const styles = {
  outlined: (error) => {
    const border = `1px solid ${error ? '#f018a6' : '#c4c4c4'}`;
    return {
      menu: (provided, state) => ({
        ...provided,
        zIndex: 100000,
      }),
      menuPortal: base => ({ ...base, zIndex: 9999 }),
      valueContainer:(provided, state) => ({
        ...provided,
        margin: "8px",
      }),
      control: (provided, state) => ({
        ...provided,
        border,
      }),
      container: (provided) => ({
        ...provided,
        width: "100%",        
      })
    }
  },
  standard: (error) => {
    return {
      menu: (provided, state) => ({
        ...provided,
        zIndex: 100000,
      }),
      menuPortal: base => ({ ...base, zIndex: 9999 }),
      valueContainer:(provided, state) => ({
        ...provided,
        margin: "0px",
        padding: "0px"
      }),
      control: (provided, state) => ({
        ...provided,
        border: "0px solid white",
        borderBottom: "0px solid white",
        borderRadius: "0px",
        backgroundColor: "transparent"
      }),
      singleValue: (provided, state) => ({
        ...provided,
        marginLeft: "0px",
        marginRight: "0px",
      }),
      container: (provided) => ({
        ...provided,
        width: "100%",        
      }),

    }
  }
}

const getFormikValue = (items, formik, name) => {
  
  var value = formik && formik.values[name];
  if(value){
    if(Array.isArray(value)){
      return value.map(x => items.find(item => item.value === x) || x)
    }else{
      return items.filter(x => x.value === value);
    }
  }

}

const getOptions = (optionsList, data, options, renderOp, props) => {

  return new Promise((resolve, reject) => { 
    if(optionsList) {
      resolve(optionsList);
      return;
    }

    if(data[options]){
      resolve(renderOp ? data[options].map((option) => renderOp({...option, ...props})): data[options]);
      return;
    }

    data.getTable(options).then(response => 
      resolve(response)
    ).catch(error =>
      reject(error)
    )    
    
  });

}

export const MultiSelectGiz = (props) =>  {
  
  const {details = false, isMulti = true, name, caption, options, optionsList, formik, data, renderOption, variant="outlined" } = props;
  
  const error = useMemo(()=> formik && formik.errors[name], [formik, name]);
  const hasError = Boolean(error);
  
  const classes = useStyles();
  const [state, setState] = useState(()=>({items: [], value: null, controlStyle: {},  touched: false}))

  useEffect(() => {
    let v = null;
    if(state.touched){
      if(state && state.value){
        if(isMulti){
          v = state.value.map(x => x.value);
        }else{
          v = Array.isArray(state.value) ? state.value.length > 0  && state.value[0].value : state.value.value;
        }
        v !== formik.values[name] && formik.setFieldValue(name, v)
      }else{
        formik.setFieldValue(name, "")
      }  
    }
  }, [state.value])

  useEffect(() => {
    setState(state => ({...state, controlStyle: styles[variant](hasError)}));
  }, [hasError])

  const handleChange = (value) => {
    setState(state => ({
      ...state,
      value,
      touched: true,
    }))
  }
/*
  const getFormikValue = (items) => formik && formik.values[name] && items.filter(x => x.value === formik.values[name]);
*/
  useEffect(() => {
    const renderOp = renderOption ? eval(renderOption) : x => ({label: x.label, value: x.value, isDisabled: x.isDisabled, selected: x.selectedFn ? (x.selectedFn && eval(x.selectedFn)(x)) : x.selected });
    /*const items = optionsList || (data[options] && data[options].map((option) => renderOp({...option, ...props})));
    const value = items && (items.some(x => x.selected) ? items.filter(x => x.selected) : getFormikValue(items)) || null;
    console.log({items, value, gfv: getFormikValue(items)})
    setState({
      items,
      value,
      controlStyle: styles[variant](false),
    })*/

    getOptions(optionsList, data, options, renderOp, props).then(
      items => {

        const value = items && (items.some(x => x.selected) 
        ? items.filter(x => x.selected) 
        : getFormikValue(items, formik, name)) || null;
        setState(state => ({
          items,
          value,
          controlStyle: styles[variant](false),
        }))
        
      }
    ).catch(error=>
      console.log(error)
    );
  }, [])


  const wrapperStyle = variant === "standard" 
    ? { marginLeft: 8 }
    : { margin: 8 };

  return (
    <div  style={wrapperStyle}>
      <FormControl fullWidth variant={variant} className={classes.formControl} error={hasError} >
        {Boolean(state.value) && variant === "outlined" && 
          <InputLabel shrink={true} style={{paddingLeft: "4px", paddingRight: "4px"}} error={hasError}>
            {caption}
          </InputLabel>      
        }
        {state.items &&
          <Select
            isDisabled={details}
            isMulti={isMulti}
            styles={state.controlStyle}
            name={name}
            options={state.items}
            value={state.value}
            onChange={value => handleChange(value)}
            placeholder={variant === "outlined" && caption}
            menuPortalTarget={document.body}
            menuPosition="fixed"
          />
      
        }
        {hasError &&
          <FormHelperText>{error}</FormHelperText>
        }
      </FormControl>
    </div>
  )
}

export const MultiSelectGizStyles = styles;