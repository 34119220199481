import React from 'react';

export const highlightTest = (search) => new RegExp(search, 'gi');
 
export const Highlight = ({text, search}) => {
    if(!text) return <span/>
    const html = !search 
      ? text 
      : text.replace(highlightTest(search), '<b>$&</b>');
    return <span dangerouslySetInnerHTML={{__html: html}}/>;
};
