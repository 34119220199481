import React from "react";
import { getBooleanValue, PrefabBasicSelect, PrefabSelHorizontalSimpleOpciones } from "./";

export const prefabSeleccionHorizontalSimpleOpcionesCompacta = ({ style, item, onClick }) => {

  return (
    <div style={{ ...style, paddingTop: 4, paddingBottom: 4,}}>
      <PrefabBasicSelect
        onClick={onClick}
        title={item ? (item.identificacion && item.identificacion.descripcion) : "Selecc. simple hor."}
        hasHistory={item ? (item.propiedades && getBooleanValue(item.propiedades.HistorialHabilitado)) : true}
        hasInfo={item ? (item.propiedades && item.propiedades.TextoAyuda) : "Texto de ayuda"}
        content={<PrefabSelHorizontalSimpleOpciones list={item && (item.propiedades.ListaOpciones ? item.propiedades.ListaOpciones : [])}/> }
      />
    </div>
  );
  
}




