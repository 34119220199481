import React from "react"
import { DatePicker as Dtpicker } from '@material-ui/lab';
import { TextField } from "@material-ui/core";

export const DatePicker = ({name, details = false, caption = "Fecha", formik, variant = "outlined", placeholder, onChange, minDate, invalidDateMessage, minDateMessage, maxDateMessage}) => {

  return (
    <div style={{ padding: 8 }}>

      <Dtpicker
        disabled={details}
        label={variant == "outlined" && caption}
        value={formik.values[name]}
        onChange={(value)=>{ 
          formik.setFieldValue(name, value)
        }}
        renderInput={(params) => <TextField {...params}  helperText="" fullWidth variant={variant} />}
      />
    </div>
  )
}

