import React from "react";
import { Provider } from "react-redux";

const Store = ({store, children}) => {
  return (
    <Provider store={store}> 
      {children}
    </Provider>
  );
}

export default Store;