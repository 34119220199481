import React from "react";
import { PrefabBasicFull, PrefabLayoutUI, calcHeight, PictureSvg, calcFontSize } from "./";


const getValue = (item) => {

  return {
    height: item && ((item.propiedades && item.propiedades.Alto) ? calcHeight(item.propiedades.Alto) : 30) || 30,
    item:{
      fontWeight: 500,
      fontSize: item && ((item.propiedades && item.propiedades.MedidaFuente) ? calcFontSize(item.propiedades.MedidaFuente) : 10) || 10,
      color: item && ((item.propiedades && item.propiedades.ColorFrente) ? item.propiedades.ColorFrente : "white") || "white",
      valor: item && ((item.propiedades && item.propiedades.Valor) ? item.propiedades.Valor : "ETIQUETA") || "ETIQUETA",
      textTransform: item && (
        (item.propiedades && item.propiedades.Mayusculas) 
        ? "uppercase"
        : ((item.propiedades && item.propiedades.Mayusculas) 
          ? "lowercase"
          : "unset")
      ) || "unset",
      alignItems: item && 
      (
        (item.propiedades && item.propiedades.AlineacionVertical === "Arriba") 
        ? "flex-start"
        : ((item.propiedades && item.propiedades.AlineacionVertical === "Abajo") 
          ? "flex-end"
          : "center")
      ) || "center",
      textAlign: item && 
      (
        (item.propiedades && item.propiedades.AlineacionVertical === "") 
        ? "left"
        : ((item.propiedades && item.propiedades.AlineacionVertical === "Derecha") 
          ? "right"
          : "center")
      ) || "center",      
    },
  }
}


export const prefabTextoUI = ({ style, item, onClick }) =>{

  const value = getValue(item);

  return (
    <div style={style}>
      <PrefabBasicFull
        onClick={onClick}
        title={item ? (item.identificacion && item.identificacion.descripcion) : "Texto al 100%"}
        hasHistory={false}
        content={
          <PrefabLayoutUI 
            style={{height: value.height, padding: 4}}
            list={item && (item.propiedades ? [
              <div style={{flex: 1, display: "flex", flexDirection: "row", ...value.item}}>
                <div style={{textAlign: "inherit", width: "100%"}}>{value.item.valor}</div>
              </div>
            ] : [])}
            defaultValue = {[
              <div style={{flex: 1, display: "flex", flexDirection: "row", ...value.item}}>
                <div style={{textAlign: "inherit", width: "100%"}}>{value.item.valor}</div>
              </div>
            ]}
          />
        }
      />
    </div>
  );
}