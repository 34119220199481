import React from "react";
import { PrefabBasicFull, PrefabLayoutUI, calcHeight, PictureSvg } from "./";

export const prefabImagenUI = ({ style, item, onClick }) =>{

  const height = item && ((item.propiedades && item.propiedades.Alto) ? calcHeight(item.propiedades.Alto) : 25) || "unset";
  
  return (
    <div style={style}>
      <PrefabBasicFull
        onClick={onClick}
        title={item ? (item.identificacion && item.identificacion.descripcion) : "Gráfico al 100%"}
        hasHistory={false}
        content={
          <PrefabLayoutUI 
            style={{height: height, padding: 4}}
            list={item && (item.propiedades ? [<img style={{objectFit: "contain", maxWidth: "100%", maxHeight: "100%"}} src={item.propiedades.Imagen}/>] : [])}
            defaultValue = {[
              <div style={{ flexDirection: "column", display: "flex", alignItems: "center" }}>
                {<PictureSvg style={{fill: "white", textAlign: "center", marginLeft: 60, marginRight: 60 }}/>}
              </div>
            ]}
          />
        }
      />
    </div>
  );
}