import React, { useState } from "react";
import Box from '@material-ui/core/Box';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Field } from "../";
import { Grid } from "@material-ui/core";
import { createValidationObject, useSlimFormik } from "../../../redux/utils";
import { SelectClienteGiz } from "../";
import { useFormContext } from "../../contexts/FormCtx";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { useHistory } from "react-router";

const fieldToItem = (props) => ({
  label: props.field.caption,
  description: <Field fake={false} {...props} />,
  validationSchema: props.validationSchema,
  disabled: false,

})

const fieldToCliente = (props) => ({
  label: props.field.caption,
  description: <SelectClienteGiz key={props.key} caption={props.caption} data={props.data} name={props.name} formik={props.formik} />,
  validationSchema: props.validationSchema,
  disabled: !props.formik.values._cliente || !props.validationSchema.isValidSync(props.formik.values._cliente),
})

const newItemValues = ({fields, item, data, name, caption}) => {
  return fields.reduce((p, c) => {return {...p, [c.name]: c.defaultValue && eval(c.defaultValue)({item, data, name, caption, fields})};}, {});
}

export const OrdenGiz = (props) => {
  let history = useHistory();
  const { rootValues, data, item, validationSchema, fields } = props; 
  const formContext = useFormContext();

  const formik = useSlimFormik({
    initialValues: props.formik.values,
    onSubmit: (values) => {
      console.log(props);
      console.log(formContext);

      formik.setSubmitting(true);
      formContext.addItem({propiedades: values}).then(response => {
        formContext.snack.openSuccess(`La orden se creó correctamente.`);
        history.replace( toAbsoluteUrl(`${formContext.controller}/0/Index`) );
      }).catch(error => {
        formContext.snack.openError('Error de servidor.');
        formik.setSubmitting(false);
      })

      //console.log(props.formik.values);
      //props.formik.submitForm();
    },
    onChange: values => {
      console.log(values)
      props.formik.setValues({propiedades: values});
    }
  })

  const fieldTareas = fields.find(x => x.name === "_tareas");
  const fieldCliente = fields.find(x => x.name === "_cliente");
  const fieldDespacho = fields.find(x => x.name === "_despacho");

  const stepsObject = {
    tareas: fieldToItem({key: fieldTareas.name, fields, rootValues, formik, data, item, validationSchema: validationSchema.fields[fieldTareas.name], field:fieldTareas}),
    cliente: fieldToCliente({key: fieldCliente.name, fields, rootValues, formik, data, item, validationSchema: validationSchema.fields[fieldCliente.name], field:fieldCliente}),
    caratulas: (formik.values["_tareas"] || (data.tareas && data.tareas.map(x => ({...x, checked: x.required}))) || [])
      .reduce((prev, tarea)=> tarea.checked ? [...prev, ...tarea.customProperties.map(cp => ({...cp, caption: `${tarea.label} - ${cp.caption}`}))] : prev, [])
      .map((x, key)=> fieldToItem({
        key, 
        fields: x.fields, 
        rootValues, 
        formik, 
        data, 
        item, 
        onChange: handleChangeCaratula,
        validationSchema: createValidationObject(x), 
        field: {...x, initialValues: newItemValues({fields: x.fields, item, data, name: x.name, caption: x.caption})},
        grouping: false
      })),
    despacho: fieldToItem({key: fieldDespacho.name, fields, rootValues, formik, data, item, validationSchema: validationSchema.fields[fieldDespacho.name], field:fieldDespacho }),
  };  


  const steps = [
    stepsObject.tareas,
    stepsObject.cliente,
    ...stepsObject.caratulas,
    stepsObject.despacho,
  ];

/*
  stepsObject.cliente.validationSchema.validate((formik.values && formik.values.cliente) || {})
  .then(
    console.log("cliente valido")
  )
  .catch(e => console.log(e))
*/

  /*
  useEffect(()=>{
    console.log ({mensaje: "cambio cliente", cliente: formik.values.cliente});
    stepsObject.cliente.validationSchema.validate(formik.values.cliente).then(r => {
      console.log("todo ok");
    }).catch(e => {
      console.log({mensaje: "error", e})
    });

    console.log ({mensaje: "cambio cliente", cliente: formik.values.cliente});
  }, [formik.values.cliente])
*/

  const handleChangeCaratula = (values) =>{
    console.log(values)
  }

  const handleConfirm = () => {
    formik.submitForm();    

    //console.log("formContext")
    //console.log(formContext)
    //console.log(formik.values)
    //!formik.values.propiedades && formik.setValues ({propiedades: formik.values});
    //formik.submitForm();
  }

  //console.log({steps, fields, caratulas})
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={2}></Grid>
        <Grid item xs={12} md={8}>
        <OrdenMobileStepper steps={steps} onConfirm={handleConfirm}/>
        </Grid>
        <Grid item xs={12} md={2}></Grid>
      </Grid>

    </>
  );
}

const OrdenMobileStepper = (props) => {
  const {steps, onConfirm} = props;
  const [activeStep, setActiveStep] = useState(0);

  const step = steps[activeStep];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  //console.log(steps[activeStep].disabled)
  return (
    <>
      <Box sx={{ flexGrow: 1 }} style={{minHeight:480 }}>
        <Stepper activeStep={activeStep} steps={steps} onConfirm={onConfirm} onNext={handleNext} onBack={handleBack} />
        <Paper
          square
          elevation={0}
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: "1px solid #f0f0f0;",
          }}
        >
          <Grid container p={2} spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">{step.label}</Typography>
            </Grid>
            <Grid item xs={12} style={{}}>
              {step.description}
            </Grid>

          </Grid>
        </Paper>
      </Box>
      
    </>
  );
}


const Stepper = ({position = "static", activeStep, steps, onConfirm, onNext, onBack}) => {
  const maxSteps = steps.length;

  return (
    <MobileStepper
      style={{backgroundColor: "#f7f7f7"}}
      steps={maxSteps}
      position={position}
      activeStep={activeStep}
      backButton={
        <Button size="small" onClick={onBack} disabled={activeStep === 0}>
          <KeyboardArrowLeft /> Anterior
        </Button>
      }
      nextButton={
        <>
          {activeStep < (maxSteps - 1)
            ?<Button size="small" onClick={onNext} disabled={steps[activeStep].disabled} >
              Próximo <KeyboardArrowRight />
            </Button>
            :<Button size="small" onClick={onConfirm} >
              Confirmar
            </Button>
          }
        </>
      }
    />
  )
}
