import React from 'react';
import { login } from '../../services/ApiService.jsx';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useAuthContext} from '../../contexts/Auth.jsx';
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { useFeatureAccessContext } from '../../contexts/FeaturesAccess.jsx';
import { useSnackContext } from '../../contexts/Snack.jsx';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { TextGiz } from '../../helpers/';

export const LoginPath = "/Login";

const validationSchema = yup.object({
  usuario: yup.string().required("Este dato es requerido"),
  password: yup.string().required("Este dato es requerido").max(20, "La longitud máxima es de 20 caracteres."),
})

export const Login = (props) => {
  let history = useHistory();
  const {menu} = useFeatureAccessContext();
  const {openAxiosError} = useSnackContext();

  const {auth, onAuth, } = useAuthContext();

  const formik = useFormik({
    validationSchema,
    initialValues: {}, 
    onSubmit: values => {
      formik.setSubmitting(true);
      login(values.usuario, values.password)
        .then(response => {
          formik.setSubmitting(false);
          onAuth (response.data.token) 
          history.push("/");
        })
        .catch(error => {
          formik.setSubmitting(false);
          openAxiosError(error);
        });
    }
  })

  if(auth.isAuthenticated){
    return <Redirect to="/" />;
  }

  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div className="login login-4 login-signin-on d-flex flex-row-fluid" id="kt_login">
          <div className="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat bg-login" style={{backgroundImage: `url(${toAbsoluteUrl('media/bg/bg-3.jpg')})`}}>
            <div className="login-form text-center p-7 position-relative overflow-hidden">
              <div className="mt-10">
              </div>
              <div className="d-flex flex-center mb-15">
                <Link to="/">
                  <img src={toAbsoluteUrl(menu.loginImg)} className="max-h-75px" alt="" />
                </Link>
              </div>
              <div className="login-signin">
                <div className="mb-15">
                  <h3>Acceso a la Consola</h3>
                  <div className="text-muted font-weight-bold">Ingresa los datos de acceso para entrar a tu cuenta:</div>
                </div>
                <form>
                  <div className="form-group mb-5">
                    <TextGiz name="usuario" autoFocus caption="Usuario" formik={formik} />
                  </div>
                  <div className="form-group mb-5">
                    <TextGiz name="password" caption="Password" formik={formik} type="password" />
                  </div>
                  <div className="form-group d-flex flex-wrap justify-content-between align-items-center">

                  </div>
                </form>
                <input onClick={formik.handleSubmit} type="submit" id="kt_login_signin_submit_x" className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4" value="Acceder"/>
                <div className="mt-10">
                </div>                          
                <div className="mt-10">
                  <span className="opacity-70 mr-4"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}