import React from "react";
import { PrefabContainer, PrefabItem, PrefabRow } from "./";

export const PrefabBase = ({ rows, onClick=()=>{} }) => {

  return (
    <div style={{width: "100%", maxWidth: 338, backgroundColor: "#393a39", width: 338 }} onClick={onClick}>
      <PrefabContainer>
        {
          rows.map( (row, rowIndex) => 
            <>
              {row && 
                <PrefabRow key={rowIndex}>
                  {
                    row.items.map( (item, itemIndex) =>
                      <>
                        {(item.content || item.component) &&
                          <PrefabItem key={itemIndex} grow={item.px}>
                            {item.component ? <item.component>{item.content}</item.component> : item.content}
                          </PrefabItem>                
                        }
                      </>
                    )
                  }
                </PrefabRow>
              }
            </>
          )
        }
      </PrefabContainer>    
    </div>
  );
}



