import { Button, CardHeader } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';
import { Form } from './Form';

const Add = (props) => {
  const { config } = props;
  let history = useHistory();

  const handleBackClick = (event) => {
    event.preventDefault();
    history.goBack();
  }

  return (
    <>
      <CardHeader
        action={
          <Button onClick={handleBackClick}>
            Cancelar
          </Button>
        }
        title={config.caption}
        subheader="Agregar"
      />
      <Form {...props} action="add"/>
    </>
  );
};

export default Add;